import React from 'react';
import {CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon} from '@heroicons/react/20/solid'

const InlineNotification = ({error = [], info = [], warning = [], ok = [], neutral = []}) => {


    // TailwindCSS purge safelist hints
    // These are not real components, just hints for the purge process
    const purgeCSSHints = (
        <>
            <div className="rounded-md bg-red-50 p-3"></div>
            <div className="rounded-md bg-blue-50 p-3"></div>
            <div className="rounded-md bg-yellow-50 p-3"></div>
            <div className="rounded-md bg-green-50 p-3"></div>
            <div className="h-5 w-5 text-red-400"></div>
            <div className="h-5 w-5 text-blue-400"></div>
            <div className="h-5 w-5 text-yellow-400"></div>
            <div className="h-5 w-5 text-green-400"></div>
            <p className="text-sm font-medium text-red-800"></p>
            <p className="text-sm font-medium text-blue-800"></p>
            <p className="text-sm font-medium text-yellow-800"></p>
            <p className="text-sm font-medium text-green-800"></p>

            <div className="rounded-md bg-gray-50 p-3"></div>
            <div className="h-5 w-5 text-gray-400"></div>
            <p className="text-sm font-medium text-gray-800"></p>
        </>
    );

    const createNotification = (messages, color, IconComponent) => {
        return messages.filter(message => message).map((message, index) => (
            <div key={index} className={"rounded-md bg-" + color + "-50 p-2 mb-2"}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        <IconComponent className={"h-5 w-5 text-" + color + "-400"} aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <p className={"text-sm font-medium text-" + color + "-800"}>{message}</p>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <>
            {createNotification(error, 'red', XCircleIcon)}
            {createNotification(info, 'blue', InformationCircleIcon)}
            {createNotification(warning, 'yellow', ExclamationTriangleIcon)}
            {createNotification(ok, 'green', CheckCircleIcon)}
            {createNotification(neutral, 'gray', InformationCircleIcon)}
        </>
    );
}

export default InlineNotification;