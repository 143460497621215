import React from "react";
import {Link} from "react-router-dom";

export default function ShowMessage({groupID, resp}) {
    return (
        <div className="w-full bg-gray-50 sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>{resp.content.message}</p>
                </div>
                <div className="mt-5">
                    <Link
                        to={`/feed?groupID=${groupID}`}
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Sluiten
                    </Link>
                </div>
            </div>
        </div>
    )
}
