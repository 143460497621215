import React, {useEffect, useRef, useState} from "react";
import {Combobox} from "@headlessui/react";
import {ClassNames} from "../util";
import {ChevronUpDownIcon, XMarkIcon} from "@heroicons/react/20/solid";

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

function FilterBox({options, placeholder, selected, setSelected}) {
    const [loading, setLoading] = useState(true);  // Loading state
    const [query, setQuery] = useState('');
    const selectedOption = options ? options.find(option => (option.id && option.id === selected) || option === selected) : null;
    const selectedDisplayValue = selectedOption ? (selectedOption.name || selectedOption) : null;
    const filteredOptions = query === '' ? options : options.filter((option) => {
        let optionName = option.name || option;
        return optionName ? optionName.toLowerCase().includes(query.toLowerCase()) : '';
    });

    const inputRef = useRef(null);
    const textWidthRef = useRef(null);

    useEffect(() => {
        if (!loading && inputRef.current) {
            const font = `16px Roboto, sans-serif`; // Your input font here
            const textToMeasure = selectedDisplayValue || placeholder;
            const textWidth = getTextWidth(textToMeasure, font);
            const paddingAndIconsWidth = 42; // Adjust for padding and icons
            inputRef.current.style.width = `${textWidth + paddingAndIconsWidth}px`;
        }
    }, [selectedDisplayValue, placeholder, loading]);

    useEffect(() => {
        if (options) {
            setLoading(false); // Set loading to false when options are ready
        }
    }, [options]);

    if (!options) return;
    if (options.length === 0) return null;

    return (
        <Combobox className="pr-2" as="div" value={selectedDisplayValue} onChange={setSelected}>
            <div className="relative mt-2">
                <Combobox.Input
                    ref={inputRef}
                    style={{width: 'min-content', maxWidth: '100%'}}
                    className={ClassNames(
                        selectedDisplayValue ? 'bg-blue-100 focus:ring-0' : 'ring-1 bg-white shadow-sm ring-inset ring-gray-300 focus:ring-2',
                        'rounded-md py-1 pl-3 pr-8 text-gray-900 border-0',
                        'ring-inset ring-gray-300 focus:ring-inset focus:ring-blue-400 text-sm leading-6 '
                    )}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(option) => option?.name || option}
                    placeholder={placeholder}
                />

                {selectedDisplayValue ? (
                        <button
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-2 focus:outline-none"
                            onClick={() => {
                                setSelected(null);
                                setQuery('');
                            }}>
                            <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </button>
                    ) :
                    <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </Combobox.Button>
                }

                {filteredOptions && filteredOptions.length > 0 && (
                    <Combobox.Options
                        className="min-w-[250px] absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredOptions.map((option) => (
                            <Combobox.Option
                                key={option.id || option}
                                value={option.id || option}
                                className={({active}) =>
                                    ClassNames(
                                        'relative cursor-default select-none py-2 pl-2',
                                        active ? 'bg-blue-400 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                <span className={ClassNames('block truncate')}>{option.name || option}</span>
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
            <span ref={textWidthRef} className="hidden">
                {selectedDisplayValue || placeholder}
            </span>
        </Combobox>
    );
}

export default FilterBox;