import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

const Example = ({children}) => {
    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    return (
        <>
      <span role="button" tabIndex={0} onClick={handleClick} className="underline">
        {children}
      </span>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                                >
                                    <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg text-center font-semibold leading-6 text-gray-900">
                                                Privacy Agreement
                                            </Dialog.Title>
                                            <div className="mt-2 overflow-y-auto h-100">
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">De AVG/GDPR is
                                                    een wet ter bescherming van privacy en
                                                    persoonsgegevens. Op grond van deze wet heeft een organisatie die
                                                    met persoonsgegevens werkt bepaalde plichten
                                                    en heeft diegenen van wie de gegevens zijn, bepaalde rechten. Dit
                                                    privacyreglement is bedoeld om u te
                                                    informeren over uw rechten en onze plichten die gelden op grond van
                                                    de AVG en GDPR.</p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">GeeftAntwoord.be</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">Binnen de
                                                    toepasssing GEEFTANTWOORD.BE worden diverse
                                                    persoonsgegevens van u verwerkt. Dit doen we in opdracht van uw
                                                    behandelende zorgverlener en is noodzakelijk
                                                    om de administratieve, medische en andere vragen die u hen wil
                                                    stellen goed te kunnen laten afhandelen door uw
                                                    vertrouwde zorgverlener.<br/>Door het gebruik van de
                                                    website/toepassing laat u bepaalde gegevens bij ons achter.
                                                    Dat kunnen gewone persoonsgegevens zijn of persoonsgegevens van
                                                    gevoelige aard zoals medische gegevens . Deze
                                                    toepassing bewaart en gebruikt uitsluitend de persoonsgegevens die
                                                    rechtstreeks door u worden opgegeven of
                                                    waarvan bij opgave duidelijk is dat ze aan ons worden verstrekt om
                                                    te verwerken in het kader van de gestelde
                                                    vraag. Deze gegevens worden binnen de applicatie bewaard tot
                                                    maximaal 1 maand na het afsluiten van uw vraag
                                                    door de (para-medicus).<br/> Volgende gegevens worden verzameld bij
                                                    de website: achternaam, voornaam, adres,
                                                    telefoonnummer, geboortedatum, evt. e-mail adres, de inhoud van uw
                                                    vraag en de inhoud van de antwoorden van de
                                                    gecontacteerde zorgverlener. Deze gegevens worden uitsluitend
                                                    gebruikt voor de volgende doelen: het
                                                    aanmaken/inloggen van uw account, opvolgen en beantwoorden van uw
                                                    vragen, vlotte werking/beheer van de
                                                    praktijk van de zorgverlener. Door gebruik te maken van deze
                                                    toepassing stemt u in met de verwerking en het
                                                    gebruik van de door u verschafte gegevens binnen de geeftantwoord.be
                                                    toepassing en de praktijk van de
                                                    zorgverlener.</p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">De plichten van
                                                    GeeftAntwoord.be</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">De exploitanten
                                                    van de toepassing zijn volgens de AVG
                                                    verantwoordelijk voor:
                                                    <ul className="ml-3 pt-4 pl-4 pb-4 list-disc list-outside">
                                                        <li>de correcte en veilige verwerking van de persoonsgegevens
                                                            die u binnen deze applicatie registreert
                                                            binnen de grenzen van het doel van deze applicatie en zo
                                                            lang de informatie deel uit maakt van de
                                                            applicatie. Wanneer u als gebruiker zelf screenshots neemt
                                                            of op een andere manier informatie uit de
                                                            applicaite onttrekt, bent u zelf verantwoordelijk om deze
                                                            veilig te verwerken of op te slaan.
                                                        </li>
                                                        <li>Het zo goed mogelijk beveiligen binnen de grenzen van de
                                                            applicatie van de door u verstrekte
                                                            persoonsgegevens volgens de actuele standaarden en normen.
                                                        </li>
                                                        <li>
                                                            Het verwijderen van de informatie binnen de applicatie die
                                                            niet meer nodig is voor de correcte verwerking
                                                            van uw vraag door alle communicatie in verband met een
                                                            afgesloten vraag binnen de maand na het afsluiten uit
                                                            het systeem te verwijderen
                                                        </li>
                                                    </ul>
                                                </p>

                                                <h>De plichten van de zorgverlener (of praktijk waartoe deze behoort)
                                                </h>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">Dezej zijn
                                                    volgens de AVG de verantwoordelijke voor de
                                                    verwerking van persoonsgegevens binnen de applicatie van
                                                    GeeftAntwoord.be en die in hun praktijk plaats vindt.
                                                    Uw gegevens worden door hen voor specifieke doeleinden verzameld:
                                                    <ul className="ml-3 pt-4 pl-4 pb-4 list-disc list-outside">
                                                        <li>Voor zorgverlening</li>
                                                        <li>Voor doelmatig beheer en beleid</li>
                                                        <li>Voor voorlichting</li>
                                                        <li>We letten er op dat onze verzameling van persoonsgegevens
                                                            beperkt blijft tot alleen datgene dat echt
                                                            nodig is.
                                                        </li>
                                                        <li>U wordt op de hoogte gebracht van het feit dat er
                                                            persoonsgegevens van u verwerkt worden. Dit kan
                                                            gebeuren door uw zorgverlener (mondeling toestemming,
                                                            inlezen ID), maar ook via de applicatie
                                                            geeftantwoord.be, de website van de zorgverlener of het
                                                            infobord in de wachtkamer.
                                                        </li>
                                                        <li>Alle medewerkers binnen de praktijk van de zorgverlener en
                                                            bedrijven waarmee de zorgverlender
                                                            samenwerkt, hebben zich verplicht om vertrouwelijk om te
                                                            gaan met uw persoonsgegevens.
                                                        </li>
                                                        <li>Uw persoonsgegevens worden goed beveiligd tegen onbevoegde
                                                            toegang.
                                                        </li>
                                                        <li>Uw persoonsgegevens worden binnen de praktijk van de
                                                            zorgverlener niet langer bewaard dan noodzakelijk.
                                                            Voor medische gegevens is deze bewaartermijn in principe 30
                                                            jaar.
                                                        </li>
                                                    </ul>
                                                </p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">Uw rechten als
                                                    betrokkenen</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">U heeft de
                                                    volgende rechten:
                                                    <ul className="ml-3 pt-4 pl-4 pb-4 list-disc list-outside">
                                                        <li> Het recht om te weten of en welke persoonsgegevens verwerkt
                                                            worden.
                                                        </li>
                                                        <li> Het recht op inzage en afschrift van die gegevens. (voor
                                                            zover dat de privacy van een ander daardoor
                                                            niet wordt geschaad)
                                                        </li>
                                                        <li> Het recht op correctie, aanvulling of verwijdering van
                                                            gegevens indien dat nodig mocht zijn.
                                                        </li>
                                                        <li> Het recht om gedeeltelijke vernietiging van uw medische
                                                            gegevens te vragen. Hieraan kan alleen tegemoet
                                                            gekomen worden als het bewaren van de gegevens voor een
                                                            ander niet van aanmerkelijk belang is en de
                                                            gegevens op grond van een wettelijke regeling niet bewaard
                                                            moeten blijven.
                                                        </li>
                                                        <li> Het recht op het toevoegen van een eigen verklaring (van
                                                            medische aard) aan uw dossier.
                                                        </li>
                                                        <li> Het recht om u in bepaalde gevallen tegen de verwerking van
                                                            uw gegevens te verzetten.
                                                        </li>
                                                    </ul>
                                                    Als u gebruik wilt maken van uw rechten, dan kunt u dit mondeling
                                                    kenbaar maken aan uw zorgverlener.
                                                </p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">Verstrekking op uw
                                                    persoonsgegevens aan derden:</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">Uw gegevens
                                                    worden uitdrukkelijk niet aan derden
                                                    verstrekt anders dan derden die direct betrokken zijn bij de
                                                    uitvoering van de overeenkomst tussen u en de zorgverelener
                                                    (geeftantwoord.be, specialisten, labo, apotheek)
                                                    of tenzij enige wettelijke bepaling de verzorger of geeftantwoord.be
                                                    hiertoe verplicht. De eventuele
                                                    medewerkers van de praktijk van de verzorger, alsook de bedrijven
                                                    waarmee de verzorger samenwerkt, hebben de
                                                    verplichting vertrouwelijk met uw persoonsgegevens om te gaan
                                                    (zwijgplicht).</p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">Uitwisseling van
                                                    gegevens:</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">De
                                                    geïnformeerde toestemming die u geeft om
                                                    gezondheidsgegevens te delen via geeftantwoord.be, regelt louter
                                                    de uitwisseling van deze gegevens via tussen uzelf,
                                                    geeftantwoord.be, en de door u geselecteerde
                                                    zorgverlener(s). Als u toestemming geeft, komen uw gegevens centraal
                                                    beschikbaar en kunnen ze enkel
                                                    geraadpleegd worden door een zorgverlener met een therapeutische
                                                    relatie en beperkt tot de termijn nodig om uw
                                                    vraag correct af te handelen. Wordt de toestemming door u
                                                    ingetrokken dan wordt de gedeelde informatie van het
                                                    platform verwijderd.</p>

                                                <h2 className="font-medium text-gray-900 pt-4 pb-2">Inzage, wijziging en
                                                    verwijderen van uw gegevens: vragen of
                                                    klachten</h2>
                                                <p className="mt-1 max-w-2xl mb-4 text-sm text-gray-900">Hebt u een
                                                    vraag of een klacht of wil u uw gegevens
                                                    laten aanpassen? Dan kan u ten allen tijde contact met
                                                    geeftantwoord.be of uw verzorger opnemen: Geeftantwoord.be, Oude
                                                    Diestsestraat 11a, 3473 Waanrode of
                                                    service@geeftantwoord.be. Indien u <b>geen</b> protest aantekent,
                                                    gaan wij er van uit dat u door gebruik te
                                                    maken van
                                                    het platform geeftantwoord.be, akkoord gaat:
                                                    <ul className="ml-3 pt-4 pl-4 pb-4 list-disc list-outside">
                                                        <li>Dat geeftantwoord.be de door u opgegeven medische en
                                                            persoonsgegevens bijhoudt tot 1 maand na het
                                                            afsluiten van uw vraag
                                                        </li>
                                                        <li>Dat wij uw zorgverlener u zo nodig contacteert omtrent bv:
                                                            afspraken, bespreking resultaten, veranderingen
                                                            van de praktijk, enz)
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default Example;
