import axios from 'axios';

export const auth_api = axios.create({
    baseURL: window._env_.REACT_APP_AUTH_API,
});

export const server_api = axios.create({
    baseURL: window._env_.REACT_APP_SERVER_API,
});

export function setAuthorizationHeader(token) {
    if (token) {
        auth_api.defaults.headers.Authorization = `Bearer ${token}`;
        server_api.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
        auth_api.defaults.headers.Authorization = null;
        server_api.defaults.headers.Authorization = null;
    }
}
