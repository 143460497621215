import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {server_api} from "../axios";
import Loading from "./Loading";

const Settings = ({children, groupID}) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        server_api.get(`/groups/${groupID.groupID}/decisionTrees`).then((resp) => {
            setOptions(resp.data.decisionTrees);
            setSelectedOption(resp.data.activeDecisionTreeId);
        }).finally(() => {
            setInitialLoad(false);
        });
    }, [groupID]); // Added groupID as a dependency

    const handleClick = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const handleSave = (value) => {
        server_api.post(`/groups/${groupID.groupID}/active-decision-tree`, {decisionTreeId: value}).then((resp) => {
            setSelectedOption(value);
        });
    };

    const handleCancel = () => {
        setOpen(false);
    }

    return (
        <>
      <span role="button" tabIndex={0} onClick={handleClick} className="underline cursor-pointer">
        {children}
      </span>
            <Transition.Root show={open} as={React.Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleCancel}>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={React.Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                                >
                                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            onClick={handleCancel}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>

                                    <form className="mt-5 sm:flex sm:items-center">
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Actieve
                                                belissings boom</h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Selecteer de belissings boom die actief moet zijn voor deze groep.
                                            </p>
                                            <div className="sm:col-span-3">
                                                <div className="mt-2">
                                                    <select
                                                        value={selectedOption}
                                                        onChange={(e) => handleSave(e.target.value)}
                                                        id="country"
                                                        name="country"
                                                        autoComplete="country-name"
                                                        className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:max-w-xs sm:text-sm sm:leading-6"
                                                    >
                                                        {options && (
                                                            options.map((option) => (
                                                                <option value={option.id} key={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default Settings;
