import React, {useEffect, useState} from 'react';
import {server_api} from "../axios";

const LabelsControl = ({question, onUpdateQuestion}) => {
    const [newLabel, setNewLabel] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [labels, setLabels] = useState(question.labels || []);

    useEffect(() => {
        setLabels(question.labels || []);
    }, [question]);

    const handleAddLabel = () => {
        if (!newLabel || labels.includes(newLabel)) return;
        handleApiCall(server_api.post(`/questions/${question.id}/labels`, {label: newLabel}));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddLabel();
        }
    };

    const handleRemoveLabel = (labelToRemove) => {
        handleApiCall(server_api.post(`/questions/${question.id}/remove-label`, {label: labelToRemove}));
    };

    const handleApiCall = (apiCall) => {
        setIsDisabled(true);
        apiCall.then(resp => {
            onUpdateQuestion(resp.data);
            setNewLabel('');
        }).catch(err => {
            console.error('Error updating labels:', err);
        }).finally(() => {
            setIsDisabled(false);
        });
    };

    return (
        <div>
            <p className="block text-sm font-medium leading-6 text-gray-900">labels</p>

            <div className="flex flex-wrap gap-2">
                <div className="w-1/3 relative mt-2 flex items-center">
                    <input
                        type="text"
                        value={newLabel}
                        onChange={(e) => setNewLabel(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="nieuw label"
                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                        disabled={isDisabled}
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                        <kbd
                            className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400"
                            tabIndex="0">
                            Enter
                        </kbd>
                    </div>
                </div>
                {labels && labels.map((label, index) => (
                    <div className="mt-2 pt-1.5">
                    <span key={index} className=" px-3 py-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">
                        {label}
                        <button onClick={() => handleRemoveLabel(label)} disabled={isDisabled}
                                className="ml-1 text-gray-800 hover:text-red-500">&times;</button>
                    </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LabelsControl;
