import React, {useEffect, useState} from "react";
import {auth_api} from "../axios";
import InlineNotification from "../components/InlineNotification";
import {useSearchParams} from "react-router-dom";
import Loading from "../components/Loading";
import {ActionLink} from "../layout/LandingLayout";

function EmailConfirmation() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);  // New state variable
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    useEffect(() => {
        if (!token) return;

        auth_api.post(`/email-confirmation?token=${token}`).then(() => {
            setIsConfirmed(true);
        }).catch((error) => {
            if (error.response.status === 404) setError("De email confirmatie token is niet langer geldig");
            else setError(error.response.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [token]);

    if (loading) return <Loading/>;

    return (
        <div className="pt-3">
            <InlineNotification
                error={isConfirmed ? [] : [error]}
                ok={isConfirmed ? ["Uw email is bevestigd"] : []}
            />
            <p className="mt-3 text-center text-sm text-gray-500">
                ga terug naar
                <ActionLink to="/sign-in">
                    <span className="text-blue-400 hover:text-blue-500">login</span>
                </ActionLink>
            </p>
        </div>
    );
}

export default EmailConfirmation;
