import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {server_api} from "../axios";
import Loading from "../components/Loading";
import MemberSelect from "../nodes/MemberSelect";
import MultipleChoice from "../nodes/MultipleChoice";
import {Link, Navigate} from "react-router-dom";
import {XMarkIcon} from '@heroicons/react/20/solid';
import FreeText from "../nodes/FreeText";
import ShowMessage from "../nodes/ShowMessage";
import CenteredLayout from "../layout/CenteredLayout";

export function ContinueDraft() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [resp, setResp] = useState(location.state?.prevResp);
    const {draftID} = useParams();
    const groupID = location.state?.groupID;

    useEffect(() => {
        if (resp) { // If resp is already available, no need to fetch again
            setLoading(false);
            return;
        }

        setLoading(true);
        server_api.get(`/drafts/` + draftID).then((response) => {
            setResp(response.data);
        }).catch((error) => {
            throw error;
        }).finally(() => {
            setLoading(false);
        });
    }, [resp, draftID]);

    const handleSubmit = async (payload, callback) => {
        console.log("Received Callback: ", callback);

        setLoading(true);
        try {
            const response = await server_api.patch(`/drafts/` + draftID, payload);

            if (callback) {
                await callback(response.data);  // Wait for callback to finish
            }

            setResp(response.data);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading/>;

    if (!resp || !resp.type) { // in case the draft no longer exists
        return <Navigate to={"/"}/> // navigate back to home
    }

    const renderComponent = (type, resp, payload) => {
        switch (type) {
            case 'assign_to_member':
                return <MemberSelect resp={resp} onSubmit={handleSubmit} payload={payload}/>;
            case 'multiple_choice':
                return <MultipleChoice resp={resp} onSubmit={handleSubmit} payload={payload}/>;
            case 'show_message':
                return <ShowMessage groupID={groupID} resp={resp} payload={payload}/>;
            case 'free_text':
                return <FreeText resp={resp} onSubmit={handleSubmit} payload={payload}/>;
            case 'create_question':
                return <Navigate to={`/feed?groupID=${groupID}`}/>
            default:
                return <p>Unknown type: {JSON.stringify(resp)}</p>
        }
    };
    return (
        <>
            <CenteredLayout>
                <div className="w-full flex flex-col items-end">
                    <Link to={`/feed?groupID=${groupID}`}>
                        < XMarkIcon className="h-7 w-7 cursor-pointer hover:text-red-600 mb-1" aria-hidden="true"/>
                    </Link>
                    <div className="relative w-full">
                        <h1 className="text-lg leading-6 font-medium text-gray-900">Een vraag stellen</h1>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"> Pas wel op, dit vervangt geen medische
                            consultatie.Bij dringende gevallen neemt u best
                            altijd
                            telefonish contact op.</p>
                        <div className="w-full mt-10">
                            {renderComponent(resp.type, resp, null)}
                        </div>
                    </div>
                </div>
            </CenteredLayout>
        </>
    )
        ;
}


