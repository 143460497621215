import React, {useEffect, useState} from "react";
import {auth_api} from "../axios";
import {useSearchParams} from "react-router-dom";
import InlineNotification from "../components/InlineNotification";
import Loading from "../components/Loading";
import {ActionButton, ActionLink, FormInput, Layout} from "../layout/LandingLayout";

function PasswordResetConfirmation() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const [initialLoad, setInitialLoad] = useState(true);
    const [invalidToken, setInvalidToken] = useState(false);
    const [tokenCheckError, setTokenCheckError] = useState(null);

    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {
        auth_api.get(`/password-reset-token?token=${token}`)
            .catch((error) => {
                if (error.response.status === 404) setInvalidToken(true);
                else setTokenCheckError(error.response.data);
            })
            .finally(() => setInitialLoad(false));
    }, [token]);

    if (initialLoad) return <Loading/>;

    if (tokenCheckError || invalidToken) return <div className="pt-4">
        <InlineNotification
            error={[tokenCheckError || "Passwoord reset token is niet langer geldig"]}
        />
        <BackToLogin/>
    </div>

    const handlePasswordReset = event => {
        event.preventDefault();
        setLoading(true);
        setSubmitError(null);
        auth_api.post(`/reset-password?token=${token}`, {password})
            .then(() => setIsConfirmed(true))
            .catch(error => setSubmitError(error.response.data))
            .finally(() => setLoading(false));
    };

    return (
        <Layout>
            <h2 className="mb-2 mt-4 leading-9 tracking-tight text-3xl font-extrabold text-gray-700">Paswoord reset</h2>
            <p className="mb-4 font-bold text-2xl text-gray-500">Vul hier je nieuwe paswoord in</p>
            <form onSubmit={handlePasswordReset} className="space-y-4">
                <FormInput
                    isTopRounded={true}
                    isBottomRounded={true}
                    required={true}
                    id="password"
                    type="password"
                    placeholder="Paswoord"
                    value={password}
                    disabled={loading || isConfirmed}
                    onChange={e => setPassword(e.target.value)}
                />
                <InlineNotification
                    error={[submitError]}
                    ok={isConfirmed ? ["Passwoord gewijzigd"] : []}
                />
                <ActionButton loading={loading || isConfirmed}>Reset</ActionButton>
                <BackToLogin/>
            </form>
        </Layout>
    );
}

const BackToLogin = () => (
    <p className="mt-3 text-center text-sm text-gray-500">
        ga terug naar <ActionLink to="/sign-in">
            <span className="text-blue-400 hover:text-blue-500">login</span>
        </ActionLink>
    </p>
);

export default PasswordResetConfirmation;
