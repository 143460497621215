import {Component} from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error: error};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({error: error, errorInfo: errorInfo});
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>
                <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Onverwachte
                            Fout</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, er is een onverwachte fout
                            opgetreden.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a href="/"
                               className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Ga
                                terug naar de startpagina</a>
                        </div>
                    </div>
                </main>
            </>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;