import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import {useLocation} from "react-router";

const GuestRoute = () => {
    const location = useLocation();
    const fromPath = location.state?.from?.pathname || "/home";
    const fromSearch = location.state?.from?.search || "";
    const from = fromPath + fromSearch;

    const {authenticated} = useAuth();
    if (authenticated) return <Navigate to={from}/>;

    return <Outlet/>;
};

export default GuestRoute;