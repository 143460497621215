import React, {useEffect, useState} from 'react';
import {server_api} from "../axios";
import SelectBox from "./SelectBox.js";

const options = [
    {id: "new", name: "nieuw"},
    {id: "read", name: "gelezen"},
    {id: "in progress", name: "in behandeling"},
    {id: "closed", name: "afgewerkt"}
];

const findStatusOption = (status) => {
    return options.find(option => option.id === status);
}

export default function StatusControl({question, onUpdateQuestion}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [status, setStatus] = useState(findStatusOption(question.status));
    useEffect(() => {
        setStatus(findStatusOption(question.status));
    }, [question]);

    const handleChange = (option) => {
        setIsDisabled(true);
        server_api.put(`/questions/${question.id}/status`, {status: `${option.id}`}).then((resp) => {
            onUpdateQuestion(resp.data);
        }).finally(() => {
            setIsDisabled(false);
        });
    };

    if (!status) return;

    return (
        <div>
            <p className="block text-sm font-medium leading-6 text-gray-900">status</p>
            <SelectBox
                isDisabled={isDisabled}
                options={options}
                selected={status}
                changeHandler={handleChange}/>
        </div>
    )
};
