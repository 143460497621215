import React, {useState} from "react";
import {useAuth} from "../context/AuthContext";
import InlineNotification from "../components/InlineNotification";
import {useLocation} from "react-router";
import {ActionButton, ActionLink, FormInput, Layout, RedirectActionLink} from "../layout/LandingLayout";

function SignIn() {
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email || '');

    const {signIn, loading} = useAuth();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        setError(null);
        signIn({email, password}).catch((error) => {
            if (error.response && error.response.status === 401) {
                setError("Email adres of wachtwoord is onjuist");
            } else {
                setError(error.response.data);
            }
        });
    };

    return (<>
        <Layout title={"Aanmelden"}>
            <p className="mb-3 sm:text-left text-center text-sm text-gray-500">
                Of <RedirectActionLink to="/sign-up" state={{email: email}} loading={loading}>
                <span className="text-blue-400 hover:text-blue-500">registreer een account</span>
            </RedirectActionLink>
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="relative -space-y-px rounded-md shadow-sm">
                    <div
                        className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"/>
                    <FormInput
                        isTopRounded={true}
                        id="email-address"
                        type="email"
                        placeholder="Email adres"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <FormInput
                        isBottomRounded={true}
                        id="password"
                        type="password"
                        placeholder="Wachtwoord"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <InlineNotification error={[error]}/>
                <ActionButton loading={loading}>Login</ActionButton>
                <div className="flex items-center justify-end">
                    <div className="text-sm leading-6">
                        <ActionLink to="/init-reset-password" state={{email: email}} loading={loading}>
                            <span className="text-blue-400 hover:text-blue-500">Wachtwoord vergeten?</span>
                        </ActionLink>
                    </div>
                </div>
            </form>
        </Layout>
    </>)
}


export default SignIn;