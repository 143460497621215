import React from 'react';

const StatusLabel = ({status, content}) => {
    return (
        <span
            className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 whitespace-nowrap text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20"
        >
      {content}
    </span>
    );
};

const statusTranslations = {
    new: 'nieuw',
    read: 'gelezen',
    'in progress': 'in behandeling',
    closed: 'afgewerkt',
};

export const TranslateStatus = (status) => {
    return statusTranslations[status] || status;
};

export default StatusLabel;
