import React from 'react';
import PrivacyAgreementPopup from "./PrivacyAgreementPopup";
import {ActionLink} from "../layout/LandingLayout";

export const Footer = () => {
    return (
        <footer className="bg-gray-100 py-4 text-center text-sm">
            <p>
                geeftantwoord.be © 2023 -
                <ActionLink to="https://www.cybersecured.be/geeftantwoord-contactformulier">
                    <span className="underline text-gray-900 hover:text-gray-500">contact</span>
                </ActionLink>
            </p>
        </footer>
    );
};

export const FooterInbox = () => {
    return (
        <footer className="bg-gray-100 py-4 text-center text-sm">
            <p>
                <PrivacyAgreementPopup>Algemene voorwaarden</PrivacyAgreementPopup>
                - geeftantwoord.be © 2023 -
                <ActionLink to="https://www.cybersecured.be/geeftantwoord-contactformulier">
                    <span className="underline text-gray-900 hover:text-gray-500">support</span>
                </ActionLink>
            </p>
        </footer>
    );
};
