import {useAuth} from "../context/AuthContext";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router";
import ReCAPTCHA from "react-google-recaptcha"
import {RecaptchaSiteKey} from '../constants.js';
import InlineNotification from "../components/InlineNotification";
import {ActionButton, ActionLink, BirthdaySelector, FormInput, Layout} from "../layout/LandingLayout";
import PrivacyAgreementPopup from "../components/PrivacyAgreementPopup";

function SignUp() {
    const {signUp, loading} = useAuth();
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(null);

    // input fields
    const [email, setEmail] = useState(useLocation().state?.email || '');
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [telephone, setTelephone] = useState('');
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [privacyAgreement, setPrivacyAgreement] = useState(false);

    // recaptcha
    const recaptchaRef = useRef(null)
    const [loadRecaptcha, setLoadRecaptcha] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => setLoadRecaptcha(true), 1000);
    }, []);

    const handleRecaptchaChange = captchaValue => {
        setError(null);
        setErrors([]);

        signUp({
            email,
            password,
            firstName,
            lastName,
            phoneNumber: telephone,
            captcha: captchaValue,
            dateOfBirth: `${year}-${month}-${day}`
        })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 409) {
                        setError("Email is al in gebruik");
                    } else if (error.response.data?.errors) {
                        // Check if errors is an array
                        if (Array.isArray(error.response.data.errors)) {
                            setErrors(error.response.data.errors);
                        } else {
                            // If it's not an array, create an array with the single error
                            setErrors([error.response.data.errors]);
                        }
                    } else {
                        // Handle other types of errors
                        setError("An unexpected error occurred");
                    }
                }
            })
            .finally(() => {
                recaptchaRef.current.reset();
            });
    };

    useEffect(() => {
        if (errors && errors.length > 0) {
            errors.forEach((err) => {
                console.log(err);
            });
        }
    }, [errors]);

    const handleSubmit = event => {
        event.preventDefault();
        recaptchaRef.current.execute();
    };

    const handleRecaptchaLoad = () => setRecaptchaLoaded(true);

    return (<Layout title={"Registreer je hier"}>
        <p className="sm:text-left text-center text-gray-500 font-bold">
            Vul alle onderstaande gegevens in
        </p>
        <form onSubmit={handleSubmit} className="space-y-4 mt-3">
            <div className="relative -space-y-px rounded-md shadow-sm">
                <div
                    className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"/>
                <FormInput
                    required={true}
                    isTopRounded={true}
                    id="first-name"
                    type="text"
                    placeholder="Voornaam"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
                <FormInput
                    required={true}
                    id="last-name"
                    type="text"
                    placeholder="Achternaam"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                />
                <FormInput
                    required={true}
                    id="telephone"
                    type="tel"
                    placeholder="Telefoonnummer"
                    value={telephone}
                    onChange={e => setTelephone(e.target.value)}
                />
                {
                    errors && errors.length > 0 && (
                        errors
                            .filter(err => err.field === "phoneNumber")
                            .map((err, index) => {
                                let errorMessage = "";
                                if (err.code === "invalid_format") {
                                    errorMessage = 'Geen geldig Belgische telefoonnummers'
                                }
                                return (
                                    <InlineNotification error={[errorMessage]}/>
                                );
                            })
                    )
                }
                <FormInput
                    required={true}
                    id="email"
                    type="email"
                    placeholder="Email adres"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <FormInput
                    required={true}
                    id="password"
                    type="password"
                    placeholder="Wachtwoord"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                {
                    errors && errors.length > 0 && (
                        errors
                            .filter(err => err.field === "password")
                            .map((err, index) => {
                                let errorMessage = "";
                                if (err.code === "minimum_length") {
                                    errorMessage = `Het wachtwoord moet minstens ${err.minimum_length} karakters lang zijn.`;
                                }
                                return (
                                    <InlineNotification error={[errorMessage]}/>
                                );
                            })
                    )
                }
                <BirthdaySelector
                    dayValue={day}
                    monthValue={month}
                    yearValue={year}
                    isBottomRounded={true}
                    onDayChange={(e) => setDay(e.target.value)}
                    onMonthChange={(e) => setMonth(e.target.value)}
                    onYearChange={(e) => setYear(e.target.value)}
                />
                {
                    errors && errors.length > 0 && (
                        errors
                            .filter(err => err.field === "dateOfBirth")
                            .map((err, index) => {
                                let errorMessage = "";
                                if (err.code === "invalid_value") {
                                    if (err.reason === "not_valid_date") {
                                        errorMessage = `Deze datum bestaat niet.`;
                                    } else if (err.reason === "future_date_not_allowed") {
                                        errorMessage = `Deze datum ligt in de toekomst.`;
                                    }
                                }
                                return (
                                    <InlineNotification error={[errorMessage]}/>
                                );
                            })
                    )
                }
            </div>
            <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                    <input
                        required={true}
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-bue-500 ring-offset-0"
                        checked={privacyAgreement}
                        onChange={e => setPrivacyAgreement(e.target.checked)}
                    />
                </div>
                <div className="text-sm leading-6">
                    <p className="text-gray-500">ik accepteer uitdrukkelijk de <PrivacyAgreementPopup>privacy
                        policy</PrivacyAgreementPopup></p>
                </div>
            </div>
            <InlineNotification error={[error]}/>
            <ActionButton loading={loading || !recaptchaLoaded}>Registreer</ActionButton>
            <p className="mt-2 mb-8 text-center text-sm text-gray-500">
                Heeft u al een account?{' '}
                <ActionLink to="/sign-in" state={{email: email}} loading={loading}>
                    <span className="text-blue-400 hover:text-blue-500">Log je hier in</span>
                </ActionLink>
            </p>
            <div>
                {loadRecaptcha && (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={RecaptchaSiteKey}
                        size="invisible"
                        onChange={handleRecaptchaChange}
                        asyncScriptOnLoad={handleRecaptchaLoad}
                    />
                )}
            </div>
        </form>
    </Layout>);
}

export default SignUp;