import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";

const ProtectedRoute = () => {
    const {authenticated} = useAuth();
    if (!authenticated) return <Navigate to="/sign-in"/>;
    return <Outlet/>;
};

export default ProtectedRoute;