import React, {useEffect, useState} from 'react';
import {Dialog, Switch, Transition} from '@headlessui/react';
import {CalendarDaysIcon, TrashIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {server_api} from "../axios";
import {ClassNames} from "../util";

function OutOfOfficeController({groupID}) {
    const [initialLoad, setInitialLoad] = useState(true);

    const [online, setOnline] = useState(false);
    const [doNotDisturb, setDoNotDisturb] = useState(false);
    const [message, setMessage] = useState('');

    const [isScheduleEnabled, setIsScheduleEnabled] = useState(false)

    useEffect(() => {
        refresh().then(() => {
            setInitialLoad(false);
        });
    }, [groupID]);

    const refresh = () => {
        return Promise.all([
            server_api.get(`/groups/${groupID.groupID}/out-of-office`),
            server_api.get(`/groups/${groupID.groupID}/out-of-office-schedule/enabled`)
        ]).then(([outOfOfficeResponse, scheduleEnabledResponse]) => {
            const outOfOfficeData = outOfOfficeResponse.data;
            const scheduleEnabledData = scheduleEnabledResponse.data;

            if (outOfOfficeData.reason) {
                setMessage(outOfOfficeData.reason);
                setDoNotDisturb(outOfOfficeData.doNotDisturb);
                setOnline(false);
            } else {
                setMessage("");
                setDoNotDisturb(false);
                setOnline(true);
            }

            setIsScheduleEnabled(scheduleEnabledData.enabled);
        }).finally(() => {
            setInitialLoad(false);
        });
    }

    if (initialLoad) return;

    return (
        <>
            <AutoOfflineSchedulePopup
                autoOfflineEnabled={isScheduleEnabled}
                refresh={refresh}
                groupID={groupID.groupID}
            />
            <OutOfOfficeToggle
                groupID={groupID.groupID}
                online={online}
                doNotDisturb={doNotDisturb}
                message={message}
                refresh={refresh}
            />
        </>
    );
}

function OutOfOfficeToggle({groupID, online, doNotDisturb, message, refresh}) {
    const [showSchedulePopup, setShowSchedulePopup] = useState(false);

    const handleSwitchStateChange = (online) => {
        if (!online) {
            setShowSchedulePopup(true);
        } else {
            server_api.delete(`/groups/${groupID}/out-of-office`).then(() => {
                refresh();
            });
        }
    };

    return (
        <>
            <OutOfOfficePopup
                groupID={groupID}
                isOpen={showSchedulePopup}
                close={() => setShowSchedulePopup(false)}
                refresh={refresh}
            />
            <div className="flex items-center">
                <Switch.Group as="div" className="flex items-center">
                    <Switch
                        checked={online}
                        onChange={handleSwitchStateChange}
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                    >
                        <span className="sr-only">Use setting</span>
                        <span aria-hidden="true"
                              className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                        <span
                            aria-hidden="true"
                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out ${
                                online ? 'bg-blue-400' : 'bg-gray-200'
                            }`}
                        />
                        <span
                            aria-hidden="true"
                            className={`pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out ${
                                online ? 'translate-x-5' : 'translate-x-0'
                            }`}
                        />
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                        {online ? (
                            <span className="font-medium text-gray-900">Online</span>
                        ) : doNotDisturb ? (
                                <>
                                    <span className="font-medium text-red-600">Niet Storen:</span>{' '}
                                    <span className="text-gray-500">{message}</span>
                                </>
                            ) :
                            <>
                                <span className="font-medium text-gray-900">Offline:</span>{' '}
                                <span className="text-gray-500">{message}</span>
                            </>
                        }
                    </Switch.Label>
                </Switch.Group>
            </div>
        </>
    );
}

function OutOfOfficePopup({groupID, isOpen, close, refresh}) {
    const [localReason, setLocalReason] = useState('');
    const [localDoNotDisturb, setLocalDoNotDisturb] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleClose(localReason, localDoNotDisturb);
    };

    const handleCancel = () => {
        handleClose(null, null);
    };

    const handleClose = (message, doNotDisturb) => {
        if (message !== null && message !== '') {
            server_api.post(`/groups/${groupID}/out-of-office`, {
                reason: message,
                doNotDisturb: doNotDisturb,
            }).then(() => {
                refresh();
            });
        }
        close();
    };

    return (
        <Transition.Root show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleCancel}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                            >
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        onClick={handleCancel}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Offline Gaan
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Je staat op het punt om je status op offline te zetten. Gelieve een
                                            boodschap op te geven.
                                        </p>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>

                                    <div className="mt-5 sm:flex sm:items-center">


                                        <div className="w-full sm:max-w-xs">
                                            <label htmlFor="reason" className="sr-only">
                                                Reason
                                            </label>
                                            <input
                                                type="text"
                                                name="reason"
                                                id="reason"
                                                value={localReason}
                                                onChange={(e) => setLocalReason(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 sm:text-sm sm:leading-6"
                                                placeholder="Boodschap"
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400 sm:ml-3 sm:mt-0 sm:w-auto"
                                        >
                                            Opslaan
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleCancel}
                                            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:ml-3 sm:mt-0 sm:w-auto"
                                        >
                                            Annuleren
                                        </button>


                                    </div>
                                    <div className="w-full  mt-4">
                                        <div className="w-4/5">
                                            <input
                                                type="checkbox"
                                                name="doNotDisturb"
                                                id="doNotDisturb"
                                                value={localDoNotDisturb}
                                                onChange={(e) => setLocalDoNotDisturb(e.target.checked)}
                                                className="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400"
                                            />
                                            <label className="ml-2 mt-2 text-sm text-gray-500" htmlFor="doNotDisturb">
                                                <strong>Niet Storen</strong> - Patiënten kunnen geen nieuwe vragen
                                                aan je stellen
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function AutoOfflineSchedulePopup({
                                      autoOfflineEnabled, groupID, refresh
                                  }) {
    const [rules, setRules] = useState([]);
    const [newRule, setNewRule] = useState({time: '12:00', reason: '', weekday: 0, online: false});
    const [showSchedulePopup, setShowSchedulePopup] = useState(false);


    useEffect(() => {
        refreshRules();
    }, []);

    const refreshRules = () => {
        server_api.get(`/groups/${groupID}/out-of-office-schedule`).then((resp) => {
            setRules(resp.data);
        }).then(() => {
            if (autoOfflineEnabled) refresh();
        });
    }

    const handleSwitchStateChange = (value) => {
        server_api.put(`/groups/${groupID}/out-of-office-schedule/enabled`, {
            enabled: value
        }).then(() => {
            refresh();
        });
    }

    const addRule = () => {
        if (!newRule.online && newRule.reason === '') {
            return;
        }
        server_api.post(`/groups/${groupID}/out-of-office-schedule`, newRule).then((resp) => {
            refreshRules();
        });
    };

    const removeRule = (indexToRemove) => {
        server_api.delete(`/groups/${groupID}/out-of-office-schedule/${rules[indexToRemove].id}`).then(() => {
            refreshRules();
        });
    };

    const handleCancel = () => {
        setShowSchedulePopup(false);
    };

    const weekdays = [
        {id: 1, display: 'Maandag'},
        {id: 2, display: 'Dinsdag'},
        {id: 3, display: 'Woensdag'},
        {id: 4, display: 'Donderdag'},
        {id: 5, display: 'Vrijdag'},
        {id: 6, display: 'Zaterdag'},
        {id: 0, display: 'Zondag'},
    ];

    const getWeekday = (id) => {
        return weekdays.find((day) => day.id === id).display;
    }

    return (
        <>
            <button onClick={() => setShowSchedulePopup(true)}>
                <CalendarDaysIcon className="mr-3 text-sm text-gray-500 h-5 w-5" aria-hidden="true"/>
            </button>

            <Transition.Root show={showSchedulePopup} as={React.Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleCancel}>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                            <Transition.Child
                                as={React.Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6"
                                >
                                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            onClick={handleCancel}>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg flex justify-center leading-6 font-medium text-gray-900">
                                            Automatisch Offline Schema
                                            <div className="ml-2 pt-0.5">
                                                <Switch
                                                    checked={autoOfflineEnabled}
                                                    onChange={handleSwitchStateChange}
                                                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                                                >
                                                    <span className="sr-only">Use setting</span>
                                                    <span aria-hidden="true"
                                                          className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                                                    <span
                                                        aria-hidden="true"
                                                        className={ClassNames(
                                                            autoOfflineEnabled ? 'bg-blue-400' : 'bg-gray-200',
                                                            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                                        )}
                                                    />
                                                    <span g
                                                          aria-hidden="true"
                                                          className={ClassNames(
                                                              autoOfflineEnabled ? 'translate-x-5' : 'translate-x-0',
                                                              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                                          )}
                                                    />
                                                </Switch>
                                            </div>

                                        </Dialog.Title>

                                        <div className="mt-2 flex justify-center">

                                        </div>

                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Stel regels in voor wanneer je automatisch op offline of online wilt
                                                worden
                                                gezet.
                                            </p>
                                        </div>
                                    </div>


                                    <ul className="mt-5 pl-5 space-y-2">
                                        {rules.map((rule, index) => (
                                            <li key={index} className="flex justify-between items-center pr-2">
                                            <span className="text-sm text-gray-600">
                                                <strong>{getWeekday(rule.weekday)}</strong> om <strong>{rule.time || "Geen tijd ingesteld"}</strong>, status <strong>{rule.online ? 'online' : 'offline'}.</strong>
                                                {rule.reason && <> Boodschap: <strong>{rule.reason}</strong></>}
                                            </span>

                                                <button onClick={() => removeRule(index)}>
                                                    <TrashIcon
                                                        className="h-4 w-4 text-gray-400 hover:text-red-400 cursor-pointer"/>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="mt-5 sm:mt-6">
                                        <fieldset>
                                            <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                                                <div className="flex -space-x-px">
                                                    <div className="w-1/4 min-w-0">
                                                        <input
                                                            type="time"
                                                            name="time"
                                                            value={newRule.time}
                                                            onChange={(e) => setNewRule({
                                                                ...newRule,
                                                                time: e.target.value
                                                            })}
                                                            className="relative w-full rounded-none rounded-tl-md border-0 bg-transparent py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                                                            placeholder="time"
                                                        />
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <select
                                                            name="weekday"
                                                            value={newRule.weekday}
                                                            onChange={(e) => setNewRule({
                                                                ...newRule,
                                                                weekday: parseInt(e.target.value)
                                                            })}
                                                            className="relative block w-full rounded-none rounded-tr-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6">
                                                            {weekdays.map((day) => (
                                                                <option key={day.id} value={day.id}>
                                                                    {day.display}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="flex -space-x-px">
                                                    <div className="w-1/4 min-w-0">
                                                        <select
                                                            name="online"
                                                            value={newRule.online}
                                                            onChange={(e) => {
                                                                const isOnline = e.target.value === 'true';
                                                                setNewRule({
                                                                    ...newRule,
                                                                    online: isOnline,
                                                                    reason: isOnline ? '' : newRule.reason // Clear the reason if online is true
                                                                });
                                                            }}
                                                            className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6">
                                                            <option value={"true"}>online</option>
                                                            <option value={"false"}>offline</option>
                                                        </select>
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <input
                                                            type="text"
                                                            value={newRule.reason}
                                                            onChange={(e) => {
                                                                setNewRule({
                                                                    ...newRule,
                                                                    reason: e.target.value
                                                                })
                                                            }}
                                                            className={ClassNames(
                                                                newRule.online ? 'bg-gray-200' : 'bg-transparent',
                                                                "relative block w-full rounded-none rounded-br-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                                                            )}
                                                            placeholder="Boodschap..."
                                                            disabled={newRule.online}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <div className="mt-4">
                                            <button type="button" onClick={addRule}
                                                    className="w-full inline-flex items-center justify-center rounded-md bg-blue-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                                Voeg Regel Toe
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default OutOfOfficeController;
