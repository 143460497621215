import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import {useLocation} from "react-router";

const ProtectedRoute = () => {
    const {authenticated} = useAuth();
    const location = useLocation();

    if (!authenticated) return <Navigate state={{from: location}} to="/sign-in"/>;
    return <Outlet/>;
};

export default ProtectedRoute;