import React, {useEffect, useState} from 'react';
import {server_api} from "../axios";
import SelectBox from "./SelectBox.js";

const options = [
    {id: "high", name: "hoog"},
    {id: "medium", name: "medium"},
    {id: "low", name: "laag"}
];

const findUrgencyOption = (urgency) => {
    return options.find(option => option.id === urgency);
}

const UrgencyControl = ({question, onUpdateQuestion}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [urgency, setUrgency] = useState(findUrgencyOption(question.urgency));
    useEffect(() => {
        setUrgency(findUrgencyOption(question.urgency));
    }, [question]);

    const handleChange = (option) => {
        setIsDisabled(true);
        server_api.put(`/questions/${question.id}/urgency`, {urgency: option.id}).then(resp => {
            onUpdateQuestion(resp.data);
        }).finally(() => {
            setIsDisabled(false);
        });
    };

    if (!urgency) return;

    return (
        <div>
            <p className="block text-sm font-medium leading-6 text-gray-900">urgentie</p>
            <SelectBox
                isDisabled={isDisabled}
                options={options}
                selected={urgency}
                changeHandler={handleChange}/>
        </div>
    );
};

export default UrgencyControl;
