import {useAuth} from "../context/AuthContext";
import {Navigate} from 'react-router-dom';
import React from "react";
import {useLocation} from "react-router";

const RoutRout = () => {
    const location = useLocation();
    const {authenticated} = useAuth();
    return authenticated ? <Navigate to="/home" state={location.state}/> : <Navigate to="/sign-in"/>;
}

export default RoutRout;