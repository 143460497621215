import React, {useState} from 'react'
import {PaperClipIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {ActionButton} from "../layout/LandingLayout";
import {Link} from "react-router-dom";
import {server_api} from "../axios";
import {UploadFile} from "../util";

export default function FreeText({resp, onSubmit, payload, disable = false}) {
    const [freeText, setFreeText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        if (value.length <= 1000) {
            setFreeText(value);
        }
    }

    const handleClick = () => {
        onSubmit({freeText}, uploadFile);
    }

    const uploadFile = async (resp) => {
        console.log("uploadFile", resp);
        if (selectedFile) {
            console.log("Uploading file:", selectedFile);
            await server_api.post(`/questions/${resp.content.questionId}/attachments`, {fileName: selectedFile.name}).then((response) => {
                return UploadFile(selectedFile, response.data.uploadUrl).catch(uploadError => {
                    console.error("Error uploading file:", uploadError);
                    return null; // resolve promise despite the upload error
                });
            });
        }

    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <>
            <label htmlFor="description" className="text-lg leading-6 font-medium text-gray-900">
                {resp.content.question}
            </label>
            <form action="#" className="relative mt-2">
                <div className="relative overflow-hidden rounded-lg border border-gray-300 shadow-sm
                    focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                    <label htmlFor="description" className="sr-only">Description</label>
                    <textarea
                        rows={7}
                        name="description"
                        id="description"
                        className="mb-10 block w-full resize-none border-0 py-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Write a description..."
                        value={freeText}
                        onChange={handleDescriptionChange}/>
                    <div className="absolute inset-x-0 bottom-0 flex py-2 pl-3 pr-2 items-center">
                        <label
                            className={`flex items-center -m-2.5 h-10 pl-2 justify-start rounded-full text-gray-400 ${disable ? '' : 'hover:text-gray-500'} relative w-full pr-40`}>
                            <PaperClipIcon className={`h-5 w-5 flex-shrink-0 ${disable ? '' : ''}`}
                                           aria-hidden="true"/>
                            <span className="sr-only flex-shrink-0">Voeg een file toe</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only flex-shrink-0"
                                   onChange={handleFileChange}
                                   accept=".doc,.docx,.pdf,.jpg,.jpeg,.tif,.bmp"
                                   disabled={disable}/>
                            {selectedFile &&
                                <span
                                    className="text-xs ml-2 flex-grow overflow-hidden whitespace-nowrap overflow-ellipsis">
                                 {selectedFile.name}
                            </span>
                            }
                        </label>
                        <div className="flex absolute right-3 bottom-2 items-center space-x-2">
                            <div
                                className={`text-xs py-1 ${freeText.length === 1000 ? 'text-red-500' : 'text-gray-500'}`}>
                                {freeText.length}/1000
                            </div>
                        </div>
                    </div>
                </div>
                <p className="m-2 text-xs text-gray-500">opgeladen files worden voor uw en onze veiligheid gescand
                    op
                    virussen
                    daarmee kan het even duren voordat je ze kunt downloaden</p>
                <div className="mt-4">
                    <div className="flex items-center justify-between space-x-3">
                        {
                            !payload &&
                            <div className="flex justify-center w-full md:w-1/4">
                                <ActionButton
                                    loading={!freeText}
                                    onClick={() => {
                                        handleClick({freeText});
                                    }}>
                                    volgende
                                </ActionButton>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </>
    )
}
