import React, {Fragment, useEffect, useRef, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon, PencilSquareIcon} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import Loading from "../components/Loading";
import {server_api} from "../axios";
import {ActionLink} from "../layout/LandingLayout";
import {FormatDate} from "../util";
import InlineNotification from "../components/InlineNotification";
import NavigationBar from "../components/NavigationBar";

const statuses = {
    tu: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Feed() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const groupID = queryParams.get('groupID');
    const loading = useRef(true);
    const [feed, setFeed] = useState([]);
    const [members, setMembers] = useState([]);
    const [knownGroups, setKnownGroups] = useState([]);

    useEffect(() => {
        const apiCalls = [
            server_api.get(`/feed?GroupID=${groupID}`).then((resp) => {
                setFeed(resp.data);
            }),
            server_api.get(`/groups/${groupID}/properties?Names=welcome-text`),
            server_api.get(`/groups/${groupID}/members`).then((resp) => {
                setMembers(resp.data);
            }),
            server_api.get('/groups').then((resp) => {
                setKnownGroups(resp.data);
            })
        ];

        Promise.all(apiCalls).finally(() => {
            loading.current = false;
        });

    }, [groupID]);

    const QuestionOverview = (props) => {
        return (
            <>
                <div className="mb-2">
                    <CreateQuestionButton groupID={props.groupID}/>
                </div>
                {
                    <div className="w-full flex justify-start pb-2">
                        <BackToGroups/>
                    </div>
                }

                <ul className="w-full divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl">
                    {props.feed.map((feedItem) => (
                        <Link to={`/questions/${feedItem.id}`} key={feedItem.id}>
                            <li className="flex flex-row items-center justify-between gap-x-6 hover:bg-gray-50 px-4 py-5 sm:px-6">
                                <div className="flex-grow flex flex-col items-start">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{feedItem.title}</p>
                                        {
                                            !feedItem.seen &&
                                            <p className={classNames(
                                                'text-gray-600 bg-blue-50 ring-blue-500/10',
                                                'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                            )}>
                                                update
                                            </p>
                                        }
                                        {
                                            feedItem.closed &&
                                            <p className={classNames(
                                                'text-gray-600 bg-gray-50 ring-gray-500/10',
                                                'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                            )}>
                                                gesloten
                                            </p>
                                        }
                                    </div>
                                    {/* Snippet of the content */}
                                    <div className="mt-1">
                                        <p className="line-clamp-3 text-sm text-gray-600">
                                            {feedItem.content}
                                        </p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <p className="whitespace-nowrap">
                                            <time dateTime={feedItem.createdAt}>{FormatDate(feedItem.createdAt)}</time>
                                        </p>
                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                            <circle cx={1} cy={1} r={1}/>
                                        </svg>
                                        <p className="truncate font-bold">{feedItem.assignedToName}</p>
                                    </div>
                                </div>
                                <div className="flex shrink-0 items-center gap-x-2">
                                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                                        <p className="mt-1 text-xs leading-5 text-gray-500">
                                            details
                                        </p>
                                    </div>
                                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>

            </>
        );
    }

    const BackToGroups = () => {
        if (knownGroups.length === 1) return null;
        return (
            <ActionLink className="gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                        to="/groupselect">
                <div className="flex flew-row text-gray-500 hover:text-gray-900">
                    <ChevronLeftIcon className="h-5 w-5 flex-none"/>
                    <p className="text-xs leading-5">groepen</p>
                </div>
            </ActionLink>
        );
    }

    const EmptyFeed = (props) => {
        return (
            <>
                <div className="text-center pt-4">
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Je vragenlijst is leeg</h3>
                    <p className="mt-1 text-sm text-gray-500">Je hebt momenteel geen open vragen meer open staan</p>
                </div>
                <div className="mt-4">
                    <CreateQuestionButton groupID={props.groupID}/>
                </div>
                <BackToGroups/>
            </>
        )
    }

    const CreateQuestionButton = (props) => {
        return (
            <div className="pb-2 flex justify-center relative">
                <Link to={`/ask-question?groupID=${props.groupID}`} className="block w-full text-left">
                    <button className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-400 w-full
                        px-3 py-2 text-white shadow-sm hover:bg-blue-500 focus:outline-none
                        focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:ring-offset-gray-50">
                        <PencilSquareIcon className="mr-2 h-5 w-5" aria-hidden="true"/>
                        <p className="mr-2 text-sm font-semibold">stel een vraag</p>
                    </button>
                </Link>
            </div>
        );
    }

    const WelcomeText = (props) => {
        const [welcomeText, setWelcomeText] = useState("");
        useEffect(() => {
            server_api.get(`/groups/${props.groupID}/properties?Names=welcome-text`).then((resp) => {
                setWelcomeText(resp.data[0].value);
            });
        }, [props.groupID]);
        if (welcomeText === "") return <></>;
        return (
            <div className="w-full flex justify-center mb-5">
                <p className="text-xl font-semibold text-gray-800">{welcomeText}</p>
            </div>
        );
    }


    if (loading.current) return <Loading/>;

    return (
        <>
            <NavigationBar/>
            <div
                className="flex flex-col justify-center items-center bg-white px-6 max-w-2xl mx-auto">
                <DisplayOutOfOfficeNotifications members={members}/>
                <WelcomeText groupID={groupID}/>
                {feed.length === 0 ? <EmptyFeed groupID={groupID}/> : <QuestionOverview feed={feed} groupID={groupID}/>}
            </div>
        </>
    )
}


const DisplayOutOfOfficeNotifications = ({members}) => {
    if (!members) return null;
    const outOfOfficeMembers = members.filter((member) => member.unavailable);
    if (outOfOfficeMembers.length === 0) return null;

    return (
        <div className="mt-2 mb-5"> {/* Adjusted for vertical stacking */}
            {outOfOfficeMembers.map((member) => {
                const reason = member.unavailable.reason;
                const message = reason
                    ? <span><span key="name" style={{fontWeight: 'bold'}}>{member.firstName} {member.lastName}</span> is niet beschikbaar: {reason}</span>
                    : <span><span key="name" style={{fontWeight: 'bold'}}>{member.firstName} {member.lastName}</span> is niet beschikbaar</span>;
                return (
                    <InlineNotification
                        key={member.id}
                        info={[message]}
                        className="block"
                    />
                );
            })}
        </div>
    );
};
