import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {auth_api, server_api} from "../axios";
import {jwtDecode} from "jwt-decode";
import Loading from "../components/Loading";

const TokenContext = createContext(null);

export function useTokens() {
    return useContext(TokenContext);
}

export function TokenProvider({children}) {
    const [initialLoad, setInitialLoad] = useState(true);
    const [tokens, setTokens] = useState(null);
    const [decodedToken, setDecodedToken] = useState(null);
    const refreshPromiseRef = useRef(null);

    const isTokenExpired = (token) => {
        if (!token) return true;
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decoded.exp < currentTime;
    };

    const storeTokens = (tokens) => {
        localStorage.setItem('token', JSON.stringify(tokens));
        setTokens(tokens);
        setDecodedToken(jwtDecode(tokens["accessToken"]));
    }

    const deleteTokens = () => {
        localStorage.removeItem('token');
        setTokens(null);
        setDecodedToken(null);
    }

    const refreshAccessToken = async (loadedTokens) => {
        if (refreshPromiseRef.current) {
            // If already refreshing, wait for the current refresh to finish
            return refreshPromiseRef.current;
        }

        refreshPromiseRef.current = (async () => {
            try {
                let currentToken = loadedTokens || tokens;
                if (!currentToken || !currentToken["refreshToken"] || isTokenExpired(currentToken["refreshToken"])) {
                    deleteTokens();
                    return null;  // Resolve with null if token couldn't be refreshed
                }

                const response = await auth_api.get('/refresh-tokens', {
                    headers: {Authorization: 'Bearer ' + currentToken["refreshToken"]}
                });
                storeTokens(response.data);
                return response.data;  // Resolve with the new access token
            } catch (error) {
                deleteTokens(); // This will also clear the refreshPromiseRef
            } finally {
                refreshPromiseRef.current = null; // Reset the ref
            }
        })();

        return refreshPromiseRef.current;
    };

    const loadTokens = async () => {
        const tokensStr = localStorage.getItem('token');
        if (!tokensStr) return;
        let tokens = JSON.parse(tokensStr)
        if (isTokenExpired(tokens["accessToken"])) {
            await refreshAccessToken(tokens);
        } else {
            setTokens(tokens);
            setDecodedToken(jwtDecode(tokens["accessToken"]));
        }
    }

    const attachInterceptor = (apiInstance) => {
        return apiInstance.interceptors.request.use(async config => {
                if (config.headers.Authorization) return config;  // If already set, proceed
                let accessToken = tokens["accessToken"];
                if (isTokenExpired(accessToken)) {
                    let newTokens = await refreshAccessToken();  // Refresh and get new token
                    if (newTokens && newTokens["accessToken"]) accessToken = newTokens["accessToken"];
                    if (!newTokens) return config;  // If token couldn't be refreshed, proceed
                }
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );
    };

    useEffect(() => {
        loadTokens().then(() => setInitialLoad(false));
    }, []);

    useEffect(() => {
        if (!tokens) return;

        const authApiInterceptorId = attachInterceptor(auth_api);
        const serverApiInterceptorId = attachInterceptor(server_api);

        return () => {
            auth_api.interceptors.response.eject(authApiInterceptorId);
            server_api.interceptors.response.eject(serverApiInterceptorId);
        };
    }, [tokens]);

    if (initialLoad) return <Loading/>;

    return (<TokenContext.Provider value={{
        initialLoad,
        tokens,
        decodedToken,
        storeTokens,
        deleteTokens,
    }}>
        {children}
    </TokenContext.Provider>);
}