import React from 'react';
import ReactDOM from 'react-dom/client';
import RoutRout from './routs/RootRout';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './context/AuthContext';
import ErrorBoundary from "./context/ErrorBoundary";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import GuestRoute from "./routs/GuestRout";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PasswordResetInitiation from "./pages/PasswordResetInitiation";
import ProtectedRoute from "./routs/ProtectedRout";
import Home from "./pages/Home";
import PasswordResetConfirmation from "./pages/PasswordResetConfirmation";
import {TokenProvider} from "./context/TokenContext";
import './index.css';
import EmailConfirmation from "./pages/EmailConfirmation";
import LandingPageLayout from "./layout/LandingLayout";
import {AskQuestion} from "./pages/AskQuestion";
import PageNogFound from "./pages/NotFound";
import {ContinueDraft} from "./pages/ContinueDraft";
import QuestionClientView from "./pages/QuestionClientView";
import Feed from "./pages/Feed";
import GroupSelect from "./pages/GroupSelect";
import ProtectedRoutWithRedirect from "./routs/ProtectedRoutWithRedirect";
import {TimeOutNotifier} from "./context/TimeOutNotifier";
import Inbox from "./pages/Inbox";
import QuestionMemberView from "./pages/QuestionMemberView";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <TokenProvider>
                <TimeOutNotifier>
                    <AuthProvider>
                        <BrowserRouter>
                            <Routes>

                                <Route path="/" element={<RoutRout/>}/>
                                <Route element={<LandingPageLayout/>}>
                                    <Route path="/email-confirmation" element={<EmailConfirmation/>}/>
                                </Route>

                                <Route element={<GuestRoute/>}>
                                    <Route element={<LandingPageLayout/>}>
                                        <Route path="/sign-in" element={<SignIn/>}/>
                                        <Route path="/sign-up" element={<SignUp/>}/>
                                        <Route path="/init-reset-password" element={<PasswordResetInitiation/>}/>
                                        <Route path="/reset-password" element={<PasswordResetConfirmation/>}/>
                                    </Route>
                                </Route>

                                <Route element={<ProtectedRoute/>}>
                                    <Route path="/home" element={<Home/>}/>
                                    <Route path="/groupSelect" element={<GroupSelect/>}/>
                                    <Route path="/feed" element={<Feed/>}/>
                                    <Route path="/drafts/:draftID" element={<ContinueDraft/>}/>
                                    <Route path="/inbox" element={<Inbox/>}/>
                                </Route>

                                <Route element={<ProtectedRoutWithRedirect/>}>
                                    <Route path="/ask-question" element={<AskQuestion/>}/>
                                    <Route path="/questions/:questionID" element={<QuestionClientView/>}/>
                                    <Route path="/inbox/questions/:questionID" element={<QuestionMemberView/>}/>
                                </Route>

                                <Route path="*" element={<PageNogFound/>}/>

                            </Routes>
                        </BrowserRouter>
                    </AuthProvider>
                </TimeOutNotifier>
            </TokenProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
