import React, {Fragment, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import {ActionButton} from "../layout/LandingLayout";
import {ClassNames} from "../util";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MemberSelect({resp, onSubmit, payload}) {
    const members = Object.keys(resp.content.select).map((key) => ({
        id: key,
        name: resp.content.select[key].fullName,
        online: resp.content.select[key].available,
        reason: resp.content.select[key].unavailableReason,
        doNotDisturb: resp.content.select[key].doNotDisturb,
    }));
    const initialSelectedMember = payload ? members.find(member => member.id === payload.selected) : null;
    const [selected, setSelected] = useState(initialSelectedMember);

    return (
        <div>
            <div className="w-full md:w-3/5 ">
                <Listbox value={selected} onChange={setSelected}>
                    {({open}) => (
                        <>
                            <Listbox.Label className="text-lg leading-6 font-medium text-gray-900">
                                {resp.content.question}
                            </Listbox.Label>
                            <div className="relative mt-2">
                                <Listbox.Button
                                    className="relative w-full cursor-default rounded-md bg-white py-2.5 pl-3 pr-10 text-left text-gray-900
                                shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400
                                sm:text-sm sm:leading-6">
                                 <span className="flex items-center">
                                      {
                                          !selected
                                              ? <span className="block truncate">Kies je arts</span>
                                              : <>
                                                 <span
                                                     aria-label={selected.online ? 'Online' : 'Offline'}
                                                     className={classNames(
                                                         selected.online ? 'bg-green-400' : selected.doNotDisturb ? 'bg-red-500' : 'bg-yellow-400',
                                                         'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                                     )}/>
                                                  <span className="ml-3 block truncate">
                                                      {selected.name}
                                                  </span>
                                              </>
                                      }
                                 </span>
                                    <span
                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                 </span>
                                </Listbox.Button>

                                <Transition show={open} as={Fragment} leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1
                                    ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {members.map((member) => (
                                            <Listbox.Option key={member.id} value={member}
                                                            className={({active}) =>
                                                                classNames(
                                                                    active ? 'bg-blue-400 text-white' : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }>
                                                {({selected, active}) => (
                                                    <>
                                                        <div className="flex items-center">
                                                        <span aria-hidden="true" className={classNames(
                                                            member.online ? 'bg-green-400' : member.doNotDisturb ? 'bg-red-500' : 'bg-yellow-400',
                                                            'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                                        )}
                                                        />
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                            {member.name} <span
                                                                className="sr-only"> is {member.online ? 'online' : 'offline'}</span>
                                                        </span>
                                                        </div>
                                                        {selected ? (
                                                            <span className={classNames(
                                                                active ? 'text-white' : 'text-blue-400',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
                {
                    selected && selected.reason &&
                    <div
                        className={ClassNames(selected.doNotDisturb ? "rounded-md bg-red-50 mt-2 p-2" : "rounded-md bg-yellow-50 mt-2 p-2")}>
                        <div className="flex pt-1 pb-1">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon
                                    className={ClassNames(selected.doNotDisturb ? "h-5 w-5 text-red-400" : "h-5 w-5 text-yellow-400")}
                                    aria-hidden="true"/>
                            </div>
                            {
                                selected.doNotDisturb &&
                                <div className="ml-3">
                                    <p className={"text-sm text-red-800"}>{selected.name + " wil niet gestoord worden: "}
                                        <span
                                            className="font-bold">{selected.reason}</span></p>
                                </div>
                            }
                            {
                                !selected.doNotDisturb &&
                                <div className="ml-3">
                                    <p className={"text-sm text-yellow-800"}>{selected.name + " is er even niet: "}
                                        <span
                                            className="font-bold">{selected.reason}</span></p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            {
                !payload &&
                <div className="flex justify-center pt-5 w-full md:w-1/4">
                    <ActionButton
                        loading={!selected || selected.doNotDisturb}
                        onClick={() => {
                            if (selected && selected.id) {
                                onSubmit({selected: selected.id});
                            }
                        }}>
                        volgende
                    </ActionButton>
                </div>
            }
        </div>
    )
}
