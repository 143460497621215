import {PaperClipIcon} from "@heroicons/react/20/solid";
import React from "react";
import {server_api} from "../axios";

function Attachments({question}) {
    const attachments = question && question.events
        ? question.events
            .filter(event => event.Event.type === "AddedAttachment")
            .map(event => ({
                ID: event.Event.ID,
                name: event.Event.originalFileName,
            }))
        : [];

    if (attachments.length === 0) return null;

    return (
        <div className="w-full mt-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
            <dd className="mt-2 text-sm text-gray-900">
                <ul role="list"
                    className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {attachments.map(attachment => (
                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400"
                                               aria-hidden="true"/>
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                    <span
                                                        className="truncate font-medium">{attachment.name}</span>
                                </div>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <a onClick={() => DownloadAttachment(question.id, attachment.ID, attachment.name)}
                                   className="font-medium text-blue-400 hover:text-blue-500">
                                    Download
                                </a>
                            </div>
                        </li>
                    ))}
                </ul>
            </dd>
        </div>
    );
}

async function DownloadAttachment(questionID, attachmentId, name) {
    server_api.get(`/questions/${questionID}/attachments/${attachmentId}`).then((response) => {
        downloadFile(response.data.downloadUrl, name);
    });
}

function downloadFile(url, name) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            // Create a new URL pointing to the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = name; // Set filename with extension

            // Append to the DOM and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        })
        .catch(e => console.error('Error in downloading file: ', e));
}


export default Attachments;