import {ChevronRightIcon} from '@heroicons/react/20/solid'
import CenteredLayout from "../layout/CenteredLayout";
import NavigationBar from "../components/NavigationBar";
import React, {useEffect, useRef, useState} from "react";
import {server_api} from "../axios";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";

export default function GroupSelect(props) {
    const [knownGroups, setKnownGroups] = useState(props.knownGroups || null);
    const loading = useRef(knownGroups === null);

    useEffect(() => {
        if (!loading.current) return;
        loading.current = true;
        server_api.get(`/groups`).then((response) => {
            setKnownGroups(response.data);
        }).catch((error) => {
            throw error;
        }).finally(() => {
            loading.current = false;
        });
    }, []);

    if (loading.current) return <Loading/>;

    return (
        <>
            <NavigationBar/>
            <CenteredLayout>
                <ul className="w-full divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl">
                    {knownGroups.map((group) => (
                        <Link to={group.isMember ? `/inbox?groupID=${group.id}` : `/feed?groupID=${group.id}`}>
                            <li key={group.id}
                                className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                                <div className="flex min-w-0 gap-x-4">
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                            {group.name}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex shrink-0 items-center gap-x-4">
                                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                                        {group.isMember ? (
                                            <p className="text-xs leading-5 text-gray-500">
                                                inbox
                                            </p>
                                        ) : (
                                            <p className="text-xs leading-5 text-gray-500">
                                                vragen
                                            </p>
                                        )}
                                    </div>
                                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>
            </CenteredLayout>
        </>
    )
}
