import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {server_api} from "../axios";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import {XMarkIcon, ClipboardDocumentListIcon, CheckIcon} from "@heroicons/react/20/solid";
import CenteredLayout from "../layout/CenteredLayout";
import {CommentForm} from "../components/CreateComment";
import StatusControl from "../components/StatusControl";
import UrgencyControl from "../components/UrgencyControl";
import LabelsControl from "../components/LabelsControl";
import Attachments from "../components/Attachments";
import QuestionHistory from "../components/QuestionHistory";
import {FormatDate, NewlineText} from "../util";

export default function QuestionMemberView() {
    const location = useLocation();
    const {questionID} = useParams();
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        setLoading(true);
        server_api.get(`/inbox/questions?questionID=${questionID}`).then((response) => {
            setQuestion(response.data);
        }).catch(error => {
            console.error("Error fetching question data:", error)
        }).finally(() => {
            setLoading(false)
        });
    }, [questionID]);


    if (loading) return <Loading/>;

    return (
        <CenteredLayout>
            <Link className="flex justify-end items-center w-full bg-white" to={"/"}
                  state={{filters: location.state?.filters}}>
                <XMarkIcon className="h-7 w-7 cursor-pointer hover:text-red-600 mb-1" aria-hidden="true"/>
            </Link>
            <QuestionDetails question={question}/>
            <div className="w-full flex flex-wrap gap-4">
                <div className="w-1/3">
                    <StatusControl question={question} onUpdateQuestion={setQuestion}/>
                </div>
                <div className="w-1/3">
                    <UrgencyControl question={question} onUpdateQuestion={setQuestion}/>
                </div>
                <div className="w-full"> {/* This div will take the full width */}
                    <LabelsControl question={question} onUpdateQuestion={setQuestion}/>
                </div>
            </div>


            <Attachments question={question}/>

            <CommentForm question={question} onUpdateQuestion={setQuestion}/>
            <QuestionHistory question={question}/>
        </CenteredLayout>
    );
}

function QuestionDetails({question}) {
    return (
        <div className="w-full justify-center bg-white">
            <div className="w-full flex flex-col items-end">
                <div className="w-full">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        {question.title} <CopyButton question={question}/>
                    </h3>
                    <div className="w-full mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                            <time>{FormatDate(Date.now())}</time>
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1}/>
                        </svg>
                        <p className="font-bold truncate">{question.assignedToFullName}</p>
                    </div>
                    <dl className="w-full grid grid-cols-1 ">
                        <div className="pb-4 sm:col-span-2">
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {NewlineText(question.content)}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
        </div>
    )
}

const CopyButton = ({question}) => {
    // State to manage which icon is displayed
    const [isCopied, setIsCopied] = useState(false);
    const questionPlainText = convertQuestionToPlainText(question); // Assuming 'question' is available in your component's scope

    const copyText = () => {
        navigator.clipboard.writeText(questionPlainText)
            .then(() => {
                // Indicate success by showing the check icon
                setIsCopied(true);

                // Set a timer to revert to the clipboard icon after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000); // Adjusted back to 2 seconds for better UX
            })
            .catch(err => {
                console.error("Error in copying text: ", err);
                // Optionally handle error state here
            });
    };

    return (
        <button onClick={copyText} className="inline-flex items-center gap-x-1.5 rounded-md text-gray-400
                       px-2 py-2 hover:text-gray-600 focus:outline-none">
            {isCopied ? (
                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
            ) : (
                <ClipboardDocumentListIcon className="h-5 w-5" aria-hidden="true"/>
            )}
        </button>
    );
};

function convertQuestionToPlainText(question) {
    // Destructure necessary fields from the question object
    const { title, authorFullName, content, events } = question;

    // Initialize the result string with title, author, and content
    let result = `Categorie: ${title}\nPatiënt: ${authorFullName}\nVraag: ${content}\n\nComments:\n`;

    // Filter events for added comments and concatenate them into the result string
    events.forEach(event => {
        if (event.Event.type === "AddComment") {
            const { content, createdAt } = event.Event;
            const {createdByFullName} = event;
            const formattedDate = new Date(createdAt).toLocaleString(); // Format date for readability
            result += `- ${createdByFullName}, ${formattedDate}: ${content} \n`;
        }
    });

    return result;
}