import React, {useEffect, useState} from "react";
import NoKnownGroups from "./NoKnownGroups";
import {server_api} from "../axios";
import Loading from "../components/Loading";
import {Navigate} from "react-router-dom";
import GroupSelect from "./GroupSelect";
import {useLocation} from "react-router";

function Home(props) {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [knownGroups, setKnownGroups] = useState([]);

    useEffect(() => {
        setLoading(true);
        server_api.get('/groups').then((resp) => {
            setKnownGroups(resp.data);
        }).catch((error) => {
            throw error;
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    if (loading) return <Loading/>;

    if (knownGroups.length === 0) return <NoKnownGroups/>;

    if (knownGroups.filter((group) => group.name !== "Support").length === 1) {
        if (knownGroups[0].isMember) {
            return <Navigate state={{noOtherKnownGroups: true, filters: location.state?.filters}}
                             to={`/inbox?groupID=${knownGroups[0].id}`}/>
        } else {
            return <Navigate state={{noOtherKnownGroups: true, filters: location.state?.filters}}
                             to={`/feed?groupID=${knownGroups[0].id}`}/>
        }
    }
    return <GroupSelect knownGroups={knownGroups}/>
}

export default Home;
