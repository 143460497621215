import React, {useEffect, useRef, useState} from "react";
import Loading from "../components/Loading";
import {server_api} from "../axios";
import {useLocation} from "react-router";
import {Navigate} from "react-router-dom";

export function AskQuestion() {
    const [loading, setLoading] = useState(true);
    const hasMadeRequest = useRef(false);
    const [resp, setResp] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const groupID = queryParams.get('groupID');

    useEffect(() => {
        if (hasMadeRequest.current) return;
        hasMadeRequest.current = true;
        server_api.post(`/drafts`, {groupID: groupID}).then((resp) => {
            setResp(resp.data);
        }).catch((error) => {
            throw error;
        }).finally(() => {
            setLoading(false);
        })
    }, [groupID]);

    if (loading) return <Loading/>;


    return <Navigate state={{groupID: groupID, prevResp: resp.result}} to={`/drafts/` + resp.draft.id}/>;

}