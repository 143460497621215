import React, {createContext, useContext, useEffect, useState} from 'react';
import {auth_api} from "../axios";
import {useTokens} from "./TokenContext";

const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {tokens, storeTokens, deleteTokens} = useTokens();

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    useEffect(() => {
        // if we have tokens we are authenticated
        setAuthenticated(!!tokens);
    }, [tokens]);

    const signIn = (data) => {
        setLoading(true);
        setError(null);
        return auth_api.post('/sign-in', data).then(resp => {
            storeTokens(resp.data)
            setAuthenticated(true);
        }).catch((error) => {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
                throw error;
            }
            setError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const signOut = () => {
        setLoading(true);
        setError(null);
        return auth_api.get('/sign-out').finally(() => {
            deleteTokens();
            setAuthenticated(false);
            setLoading(false);
        });
    }

    const signUp = (data) => {
        setLoading(true);
        setError(null);
        return auth_api.post('/sign-up', data).then(resp => {
            storeTokens(resp.data)
            setAuthenticated(true);
        }).catch((error) => {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
                throw error;
            }
            setError(error)
        }).finally(() => {
            setLoading(false);
        })
    }

    return (<AuthContext.Provider value={{
        authenticated,
        loading,
        signUp,
        signIn,
        signOut,
    }}>
        {children}
    </AuthContext.Provider>);
}