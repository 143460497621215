import {useAuth} from "../context/AuthContext";
import React from "react";
import {ActionLink} from "../layout/LandingLayout";

export default function NoKnownGroups() {
    const {signOut} = useAuth();

    return (
        <div className="justify-center grid min-h-full place-items-start bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Geen gekende
                    praktijken</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Er zijn momenteel geen bekende praktijken
                    gekoppeld aan dit account. Voor verdere informatie verzoeken wij u de website van de arts te
                    bezoeken waaraan u een vraag wenst te stellen.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <ActionLink onClick={signOut}>
                          <span className={`text-gray-900 hover:text-gray-500`}>
                        uitloggen <span aria-hidden="true">&rarr;</span>
                          </span>
                    </ActionLink>
                </div>
            </div>
        </div>
    )
}
