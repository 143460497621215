import {ClassNames, NewlineText, TimeAgo} from "../util";
import {ChatBubbleLeftIcon} from "@heroicons/react/20/solid";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import React from "react";

function QuestionHistory({question}) {
    const events = question && question.events ? question.events : [];

    const getVerbBasedOnType = (activityItem) => {
        switch (activityItem.Event.type) {
            case 'Created':
                return 'stelt een vraag';
            case 'AddedAttachment':
                return (
                    <>
                        uploadde <strong>{activityItem.Event.originalFileName}</strong>
                    </>
                );
            default:
                return '';
        }
    }

    return (<ul role="list" className="space-y-6 w-full pt-6">
        {events.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
                <div
                    className={ClassNames(activityItemIdx === events.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center')}
                >
                    <div className="w-px bg-gray-200"/>
                </div>
                {activityItem.Event.type === 'AddComment' ? (<>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        <ChatBubbleLeftIcon className="h-6 w-6 text-blue-400" aria-hidden="true"/>
                    </div>
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div className="flex justify-between gap-x-4">
                            <div className="py-0.5 text-xs leading-5 text-gray-500">
                                            <span
                                                className="font-medium text-gray-900">{activityItem.createdByFullName}</span> reageerde
                            </div>
                            <time dateTime={activityItem.Event.createdAt}
                                  className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {TimeAgo(activityItem.Event.createdAt)}
                            </time>
                        </div>
                        <p className="text-sm leading-6 text-gray-500">{NewlineText(activityItem.Event.content)}</p>
                    </div>
                </>) : (<>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        {activityItem.Event.type === 'Closed' ? (
                            <CheckCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true"/>) : (
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>)}
                    </div>
                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 truncate">
                                    <span
                                        className="font-medium text-gray-900">{activityItem.createdByFullName}</span> {getVerbBasedOnType(activityItem)}
                    </p>
                    <time dateTime={activityItem.Event.createdAt}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                        {TimeAgo(activityItem.Event.createdAt)}
                    </time>
                </>)}
            </li>))}
    </ul>);
}

export default QuestionHistory;