import React, {useState} from 'react'
import {RadioGroup} from '@headlessui/react'
import {ActionButton} from "../layout/LandingLayout";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MultipleChoice({resp, onSubmit, payload}) {
    const initialSelectedSettingId = payload ? payload.selected : "";

    const [selected, setSelected] = useState(initialSelectedSettingId);

    console.log(JSON.stringify(resp.content.select));

    const settings = resp.content.select.map((option) => ({
        id: option.id,
        name: option.label,
        description: option.description,
    }));

    return (
        <div className="w-full">
            <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="text-lg leading-6 font-medium text-gray-900">
                    {resp.content.question}
                </RadioGroup.Label>
                <div className="mt-2 -space-y-px rounded-md bg-white">
                    {settings.map((setting, settingIdx) => (
                        <RadioGroup.Option
                            key={setting.id}
                            value={setting.id}
                            className={({checked}) =>
                                classNames(
                                    settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                    settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                    checked ? 'z-10 border-blue-200 bg-blue-50' : 'border-gray-200',
                                    'relative flex cursor-pointer border p-4 focus:outline-none'
                                )
                            }>
                            {({active, checked}) => (
                                <>
                                <span aria-hidden="true" className={classNames(
                                    checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                    active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                )}>
                                  <span className="rounded-full bg-white w-1.5 h-1.5"/>
                                </span>
                                    <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label as="span"
                                                    className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'block text-sm font-medium')}>
                                    {setting.name}
                                  </RadioGroup.Label>
                                        {
                                            setting.description &&
                                            <RadioGroup.Description as="span"
                                                                    className={classNames(checked ? 'text-blue-700' : 'text-gray-500', 'block text-sm')}>
                                                {setting.description}
                                            </RadioGroup.Description>
                                        }

                                </span>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            {
                !payload &&
                <div className="flex justify-center pt-5 w-full md:w-1/4">
                    <ActionButton
                        loading={!selected}
                        onClick={() => {
                            if (selected) {
                                onSubmit({selected: selected});
                            }
                        }}>
                        volgende
                    </ActionButton>
                </div>
            }
        </div>
    )
}