import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {server_api} from "../axios";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import {XMarkIcon} from "@heroicons/react/20/solid";
import CenteredLayout from "../layout/CenteredLayout";
import {CommentForm} from "../components/CreateComment";
import Attachments from "../components/Attachments";
import QuestionHistory from "../components/QuestionHistory";
import QuestionDetails from "../components/QuestionDetails";

export default function QuestionClientView() {
    const location = useLocation();
    const {questionID} = useParams();
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        setLoading(true);
        server_api.get(`/questions?questionID=${questionID}`).then((response) => {
            setQuestion(response.data);
        }).catch(error => {
            console.error("Error fetching question data:", error)
        }).finally(() => {
            setLoading(false)
        });
    }, [questionID]);

    const refreshQuestion = () => {
        return server_api.get(`/questions?questionID=${questionID}`)
    }

    if (loading) return <Loading/>;

    return (
        <CenteredLayout>
            <Link className="flex justify-end items-center w-full bg-white" to={`/feed?groupID=${question.groupId}`}
                  state={{filters: location.state?.filters}}>
                <XMarkIcon className="h-7 w-7 cursor-pointer hover:text-red-600 mb-1" aria-hidden="true"/>
            </Link>
            <QuestionDetails question={question}/>
            <Attachments question={question}/>
            <CommentForm question={question} onUpdateQuestion={setQuestion} refreshCall={refreshQuestion}/>
            <QuestionHistory question={question}/>
        </CenteredLayout>
    );
}
