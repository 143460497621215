import React, {Fragment, useEffect, useMemo, useState} from "react";
import Loading from "../components/Loading";
import {server_api} from "../axios";
import {useLocation} from "react-router";
import {ClassNames, TimeAgo} from "../util";
import {Cog8ToothIcon} from '@heroicons/react/20/solid'
import {useAuth} from "../context/AuthContext";
import {ActionLink} from "../layout/LandingLayout";
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/outline";
import UrgencyLabel, {TranslateUrgency} from "../components/UrgencyLabel";
import StatusLabel, {TranslateStatus} from "../components/StatusLabel";
import {convertItems} from "../components/Translate";
import {useTokens} from "../context/TokenContext";
import {FooterInbox} from "../components/Footer";
import OutOfOfficeController from "../components/OutOfOfficeController";
import Settings from "../components/SettingsPopUp";
import {Link} from 'react-router-dom';
import FilterBox from "../components/FilterBox";


export default function Inbox() {
    // Fetching group id from URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const groupID = queryParams.get('groupID');

    return (
        <div className="grid min-h-screen grid-rows-[1fr_auto]">
            <div>
                <NavigationBarLarge groupID={groupID}/>
                <div className="mx-auto px-2 sm:px-6 lg:px-8">
                    <Questions groupID={groupID}/>
                </div>
            </div>
            <div className="mt-10">
                <FooterInbox/>
            </div>
        </div>
    )
}

function Questions({groupID}) {
    const location = useLocation();
    const {decodedToken} = useTokens();
    const loggedInUser = decodedToken?.custom_claims?.user_info?.id;

    const [initialLoad, setInitialLoad] = useState(true);

    // All questions displayed in the inbox
    const [questions, setQuestions] = useState([]);
    const [closedQuestions, setClosedQuestions] = useState([]);
    const [showAssignedTo, setShowAssignedTo] = useState(false);


    // Filter options
    const [filterOptions, setFilterOptions] = useState({});

    const [selectedFilterOptions, setSelectedFilterOptions] = useState(location.state?.filters || {assignedToId: loggedInUser});

    // Memoize selectedFilterOptions to prevent unnecessary re-renders
    const memoizedFilterOptions = useMemo(() => selectedFilterOptions, [selectedFilterOptions]);

    const setFilter = (filterName) => (value) => {
        setSelectedFilterOptions((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };

    const clearFilters = () => {
        setSelectedFilterOptions({});
    };

    const isFilteringOnStatus = () => {
        return memoizedFilterOptions.status !== null && memoizedFilterOptions.status !== undefined;
    };

    const isFilterSelected = () => Object.values(selectedFilterOptions).some(value => !!value);

    useEffect(() => {
        setShowAssignedTo(memoizedFilterOptions.assignedToId !== loggedInUser);
    }, [memoizedFilterOptions]);

    const fetchFilterOptions = () => {
        return server_api.post(`/inbox-filters-options`, {
            groupIDs: [groupID]
        }).then((resp) => {
            setFilterOptions(resp.data);
        }).catch((error) => {
            console.error('Error fetching filter options:', error);
            throw error;
        });
    }

    const fetchQuestions = () => {
        return server_api.post(`/inbox`, {
            groupIDs: [groupID],
            ...(!isFilteringOnStatus() ? {excludeClosed: true} : {}),
            ...memoizedFilterOptions,
        }).then((resp) => {
            setQuestions(resp.data);
        }).catch((error) => {
            console.error('Error fetching questions:', error);
            throw error;
        });
    };

    const fetchClosedQuestions = () => {
        return server_api.post(`/inbox`, {
            groupIDs: [groupID],
            ...memoizedFilterOptions,
            status: "closed",
        }).then((resp) => {
            setClosedQuestions(resp.data);
        }).catch((error) => {
            console.error('Error fetching closed questions:', error);
            throw error;
        });
    };

    useEffect(() => {
        const apiCalls = [fetchQuestions(), fetchFilterOptions()];

        if (!isFilteringOnStatus()) {
            apiCalls.push(fetchClosedQuestions());
        } else {
            setClosedQuestions([]);
        }

        Promise.all(apiCalls).finally(() => {
            setInitialLoad(false);
        });
    }, [groupID, memoizedFilterOptions]);

    const allFiltersNull = () => {
        return Object.values(filterOptions).every(value => !value);
    }

    const filterBox = (placeholder, filterName, options) => (props) => {
        return (<FilterBox{...props} selected={selectedFilterOptions[filterName]}
                          setSelected={setFilter(filterName)}
                          onClearAll={clearFilters} placeholder={placeholder} options={options}/>);
    };

    if (initialLoad) return <Loading/>

    return (
        <>
            {allFiltersNull() &&
                <div className="text-center pt-4">
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">De inbox is leeg</h3>
                    <p className="mt-1 text-sm text-gray-500">Er zijn momenteel geen vragen voor deze groep</p>
                </div>
            }

            {!allFiltersNull() &&
                <>
                    <div className="flex flex-row flex-wrap pb-5 ">

                        {filterBox("van", "authorID", filterOptions.authors)()}
                        {filterBox("naar", "assignedToId", filterOptions.assignedTos)()}
                        {filterBox("label", "label", filterOptions.labels)()}
                        {filterBox("categorie", "category", filterOptions.categories)()}
                        {filterBox("status", "status", convertItems(filterOptions.statuses, TranslateStatus))()}
                        {filterBox("urgentie", "urgency", convertItems(filterOptions.urgencies, TranslateUrgency))()}

                        {isFilterSelected() && (
                            <button onClick={clearFilters}
                                    className="text-xs text-blue-500 hover:underline mt-2">
                                Clear All Filters
                            </button>
                        )}
                    </div>

                    <Example inboxItems={questions} showAssignedTo={showAssignedTo}
                             filters={selectedFilterOptions}/>
                    {closedQuestions.length > 0 && (
                        <>
                            <div className="relative flex pt-4 pb-2 items-center">
                                <div className="flex-grow border-t border-gray-300"></div>
                                <span className="text-sm flex-shrink mx-4 text-gray-400">afgewerkt</span>
                                <div className="flex-grow border-t border-gray-300"></div>
                            </div>
                            <Example showAssignedTo={showAssignedTo} inboxItems={closedQuestions}
                                     filters={selectedFilterOptions}/>
                        </>
                    )}
                </>}
        </>
    )
}

function Example({inboxItems, showAssignedTo, filters}) {
    return (
        <div id="main-container">
            <div id="flow-root" className="flow-root">
                <div id="overflow-x-container" className="-mx-2 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div id="inner-container" className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div
                            id="table-container"
                            className="overflow-hidden ring-opacity-5 rounded-md">
                            {inboxItems.map((item, idx) => (
                                <Link to={`/inbox/questions/${item.id}`} state={{filters: filters}} key={item.id}>
                                    <div key={item.id} id={`grid-item-${idx}`}
                                         className={ClassNames(item.status === 'closed' ? 'opacity-50' : "", "text-sm", "grid gap-2", "relative hover:bg-gray-100", item.status === 'closed' ? '' : !item.memberSeen ? '' : 'bg-gray-50', "grid-cols-[min-content,min-content,1fr,min-content,min-content,min-content]")}>
                                        <div id={`author-${idx}`}
                                             className={ClassNames('overflow-hidden truncate w-32 p-2', !item.memberSeen ? 'font-bold' : '')}>
                                            {item.authorName}
                                        </div>
                                        <div id={`assignedTo-${idx}`}
                                             className={ClassNames('overflow-hidden truncate', !item.memberSeen ? 'font-bold' : '', showAssignedTo ? "w-32 p-2" : "w-0")}>
                                            {item.assignedToName}
                                        </div>
                                        <div id={`content-${idx}`}
                                             className="overflow-hidden truncate p-2">
                                            {item.labels && item.labels.map((label) => (
                                                <span
                                                    className="inline-flex items-center rounded-md bg-gray-200 px-1 py-1 mr-1 text-xs text-gray-700 ring-inset">
                                                {label}
                                            </span>
                                            ))}
                                            <span
                                                className={ClassNames('text-gray-900', !item.memberSeen ? 'font-bold' : '')}>
                                                {item.title}
                                            </span> - <span className="text-gray-500">{item.content}</span>
                                        </div>
                                        <div id={`status-${idx}`}
                                             className="p-2 w-22 text-center">
                                            <StatusLabel status={item.status} content={TranslateStatus(item.status)}/>
                                        </div>
                                        <div id={`urgency-${idx}`}
                                             className="p-2 w-20 text-center">
                                            {
                                                item.urgency &&
                                                <UrgencyLabel urgency={item.urgency}
                                                              content={TranslateUrgency(item.urgency)}/>
                                            }
                                        </div>
                                        <div id={`time-${idx}`}
                                             className="whitespace-nowrap text-right text-gray-500 p-2 w-16">
                                            <time dateTime={item.createdAt}
                                                  className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                                {TimeAgo(item.createdAt)}
                                            </time>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NavigationBarLarge(groupID) {
    const {signOut} = useAuth();
    return (
        <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
                <div className="flex flex-none">
                    <img className="w-48" src="/logo.svg" alt="logo"/>
                </div>
                <div className="flex flex-auto justify-center">
                    <OutOfOfficeController groupID={groupID}/>
                </div>
                <div
                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                    <div className={"pr-2"}>
                        <Settings groupID={groupID}>
                            <Cog8ToothIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true"/>
                        </Settings>
                    </div>

                    <ActionLink onClick={signOut}>
                        <div className="flex items-center space-x-1 relative rounded-full bg-white
                                    p-0.5 text-sm text-gray-400 hover:text-gray-500 focus:outline-none
                                    focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">

                            <span className="sr-only">Settings</span>
                            <ArrowLeftOnRectangleIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>uitloggen</span>
                        </div>
                    </ActionLink>
                </div>
            </div>
        </div>
    )
}

