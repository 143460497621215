import React, {useEffect, useRef, useState} from 'react';
import {auth_api} from "../axios";
import InlineNotification from "../components/InlineNotification";
import {useLocation} from "react-router";
import {ActionButton, ActionLink, FormInput, Layout} from "../layout/LandingLayout";
import ReCAPTCHA from "react-google-recaptcha";
import {RecaptchaSiteKey} from "../constants";

function PasswordResetInitiation() {
    const location = useLocation();
    const initialEmail = location.state?.email || '';

    // Group related states
    const [email, setEmail] = useState(initialEmail);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const [loadRecaptcha, setLoadRecaptcha] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        setTimeout(() => setLoadRecaptcha(true), 1000);
    }, []);

    const handleRecaptchaChange = captchaValue => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        auth_api.post('/password-reset-token', {email, captcha: captchaValue})
            .then(() => setSuccess(true))
            .catch(err => setError(err.response.data))
            .finally(() => {
                recaptchaRef.current.reset();
                setLoading(false);
            });
    };

    const handleSubmit = event => {
        event.preventDefault();
        recaptchaRef.current.execute();
    };

    const handleRecaptchaLoad = () => setRecaptchaLoaded(true);

    return (
        <Layout title={"Paswoord reset"}>
            <p className="sm:text-left text-center text-gray-500 font-bold">
                Wij sturen je een reset link
            </p>
            <form onSubmit={handleSubmit} className="space-y-4 mt-3">
                <div className="relative -space-y-px rounded-md shadow-sm">
                    <div
                        className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"/>
                    <FormInput
                        disabled={loading}
                        isTopRounded="true"
                        isBottomRounded="true"
                        required="true"
                        id="email"
                        type="email"
                        placeholder="Email adres"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <InlineNotification
                    info={success ? ["Indien uw e-mailadres bij ons bekend is, is een reset-link naar u verzonden. Deze link is één uur geldig."] : []}
                    error={[error]}/>
                <ActionButton loading={loading || !recaptchaLoaded}>Verstuur</ActionButton>
                <div>
                    {loadRecaptcha && (
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={RecaptchaSiteKey}
                            size="invisible"
                            onChange={handleRecaptchaChange}
                            asyncScriptOnLoad={handleRecaptchaLoad}
                        />
                    )}
                </div>
            </form>
            <p className="mt-3 text-center text-sm text-gray-500">
                ga terug naar <ActionLink to="/sign-in" state={{email}}>
                <span className="text-blue-400 hover:text-blue-500">login</span>
            </ActionLink>
            </p>
        </Layout>
    );
}

export default PasswordResetInitiation;
