import {Fragment} from 'react'
import {Disclosure} from '@headlessui/react'
import {ArrowLeftOnRectangleIcon} from '@heroicons/react/24/outline'
import {useAuth} from "../context/AuthContext";
import {ActionLink} from "../layout/LandingLayout";

export default function NavigationBar() {
    const {signOut} = useAuth();
    return (
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                        <img className="w-48" src="/logo.svg" alt="logo"/>
                    </div>
                </div>
                <div
                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <ActionLink onClick={signOut}>
                        <div className="flex items-center space-x-1 relative rounded-full bg-white
                                    p-0.5 text-sm text-gray-400 hover:text-gray-500 focus:outline-none
                                    focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            <span className="sr-only">Logout</span>
                            <ArrowLeftOnRectangleIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>uitloggen</span>
                        </div>
                    </ActionLink>
                </div>
            </div>
        </div>
    )
}