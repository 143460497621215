export function ClassNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function FormatDate(isoString) {
    const date = new Date(isoString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
}

const secondsInMilliseconds = 1000;
const minutesInMilliseconds = 60 * secondsInMilliseconds;
const hoursInMilliseconds = 60 * minutesInMilliseconds;
const daysInMilliseconds = 24 * hoursInMilliseconds;

export function TimeAgo(timestamp) {
    const then = new Date(timestamp);
    const now = new Date();
    const differenceInMilliseconds = now - then;

    const monthNames = ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
    const differenceInDays = Math.floor(differenceInMilliseconds / daysInMilliseconds);
    if (differenceInDays > 2) {
        return `${then.getDate()} ${monthNames[then.getMonth()]}`;
    }

    const differenceInHours = Math.floor((differenceInMilliseconds % daysInMilliseconds) / hoursInMilliseconds);
    if (differenceInDays > 0) {
        if (differenceInHours > 0) {
            return `${differenceInDays}d ${differenceInHours}h`;
        }
        return `${differenceInDays}d`;
    }

    const differenceInMinutes = Math.floor((differenceInMilliseconds % hoursInMilliseconds) / minutesInMilliseconds);
    if (differenceInHours > 0) {
        if (differenceInMinutes > 0) {
            return `${differenceInHours}h ${differenceInMinutes}m`;
        }
        return `${differenceInHours}h`;
    }

    const differenceInSeconds = Math.floor((differenceInMilliseconds % minutesInMilliseconds) / secondsInMilliseconds);
    if (differenceInMinutes > 0) {
        if (differenceInSeconds > 0) {
            return `${differenceInMinutes}m ${differenceInSeconds}s`;
        }
        return `${differenceInMinutes}m`;
    }

    if (differenceInSeconds <= 10) {
        return 'zojuist';
    }

    return `${differenceInSeconds}s`;
}


export function UploadFile(file, uploadUrl) {
    return fetch(uploadUrl, {
        method: 'PUT',
        body: file
    });
}


export function NewlineText(text) {
    if (!text) return null;
    const newText = text.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br/>
        </>
    );
    return <>{newText}</>;
}
