import {FormatDate, NewlineText} from "../util";
import React from "react";

function QuestionDetails({question}) {
    return (
        <div className="w-full justify-center bg-white">
            <div className="w-full flex flex-col items-end">
                <div className="w-full">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        {question.title}
                    </h3>
                    <div className="w-full mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                            <time>{FormatDate(Date.now())}</time>
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1}/>
                        </svg>
                        <p className="font-bold truncate">{question.assignedToFullName}</p>
                    </div>
                    <dl className="w-full grid grid-cols-1 ">
                        <div className="pb-4 sm:col-span-2">
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {NewlineText(question.content)}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
        </div>
    )
}

export default QuestionDetails;