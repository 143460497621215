import React, {useState} from "react";
import {PaperClipIcon} from "@heroicons/react/20/solid";
import {server_api} from "../axios";
import {UploadFile} from "../util";
import InlineNotification from "./InlineNotification";

export const CommentForm = ({question, onUpdateQuestion, refreshCall}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [comment, setComment] = useState('');
    const [disableComment, setDisableComment] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    function handleCommentSubmit(event) {
        event.preventDefault();
        setDisableComment(true);
        setIsDisabled(true);

        let promises = [];

        if (comment.length > 0) {
            const commentPromise = server_api.post(`/questions/${question.id}/comments`, {
                comment: comment
            });
            promises.push(commentPromise);
        }

        if (selectedFile) {
            const fileUploadProcessPromise = server_api.post(`/questions/${question.id}/attachments`, {fileName: selectedFile.name}).then(uploadUrlResponse => {
                if (uploadUrlResponse.data && uploadUrlResponse.data.uploadUrl) {
                    return UploadFile(selectedFile, uploadUrlResponse.data.uploadUrl);
                }
                throw new Error("Upload URL not obtained");
            }).catch(uploadError => {
                console.error("Error in file upload process:", uploadError);
                return null; // resolve promise despite the upload error
            });
            promises.push(fileUploadProcessPromise);
        }

        Promise.allSettled(promises).then(() => {
            if (refreshCall) {
                refreshCall().then((response) => {
                    onUpdateQuestion(response.data);
                });
            } else {
                server_api.get(`/inbox/questions?questionID=${question.id}`).then((response) => {
                    onUpdateQuestion(response.data);
                });
            }
        }).finally(() => {
            setComment('');
            setSelectedFile(null);
            setDisableComment(false);
            setIsDisabled(false);
        });
    }

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        if (value.length <= 1000) {
            setComment(value);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    if (question.status === "closed" || question.closed) return (
        <div className={`mt-6 flex flex-col gap-x-3 w-full`}>
            <InlineNotification
                info={["Deze vraag is afgewerkt en er kunnen geen verdere opmerkingen worden gemaakt. Als u een andere vraag heeft, moet u een nieuwe openen."]}/>
        </div>
    );

    return (
        <div className={`mt-6 flex flex-col gap-x-3 w-full ${isDisabled ? 'opacity-50' : ''}`}>
            <form className="relative flex-auto" onSubmit={handleCommentSubmit}>
                <div
                    className="relative overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-400">
                    <label htmlFor="comment" className="sr-only">
                        Voeg uw reactie toe
                    </label>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Voeg uw reactie toe..."
                        value={comment}
                        onChange={handleDescriptionChange}
                        disabled={isDisabled}
                    />
                </div>
                <div className="absolute inset-x-0 bottom-0 flex py-2 pl-3 pr-2 items-center">
                    <label
                        className={`flex items-center -m-2.5 h-10 pl-2 justify-start rounded-full text-gray-400 ${isDisabled ? '' : 'hover:text-gray-500'} relative w-full pr-40`}>
                        <PaperClipIcon className={`h-5 w-5 flex-shrink-0 ${isDisabled ? '' : ''}`}
                                       aria-hidden="true"/>
                        <span className="sr-only flex-shrink-0">Voeg een file toe</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only flex-shrink-0"
                               onChange={handleFileChange}
                               accept=".doc,.docx,.pdf,.jpg,.jpeg,.tif,.bmp"
                               disabled={isDisabled}/>
                        {selectedFile &&
                            <span
                                className="text-xs ml-2 flex-grow overflow-hidden whitespace-nowrap overflow-ellipsis">
                                 {selectedFile.name}
                            </span>
                        }
                    </label>
                    <div className="flex absolute right-3 bottom-2 items-center space-x-2">
                        <div
                            className={`text-xs py-1 ${comment.length === 1000 ? 'text-red-500' : 'text-gray-500'}`}>
                            {comment.length}/1000
                        </div>
                        <button
                            type="submit"
                            disabled={isDisabled}
                            className="rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1
                            ring-inset ring-gray-300 bg-white hover:bg-gray-50">
                            Verstuur
                        </button>
                    </div>
                </div>
            </form>
            <p className="mt-1 text-xs text-gray-500">opgeladen files worden voor uw en onze veiligheid gescand op
                virussen daarmee kan het even duren voordat je ze kunt downloaden</p>
        </div>
    );
}