// CenteredLayout.js

import React from 'react';

function CenteredLayout({children}) {
    return (
        <div
            className="flex flex-col justify-center items-center bg-white px-6 py-5 md:py-16 lg:py-10 max-w-2xl mx-auto">
            {children}
        </div>
    );
}

export default CenteredLayout
