import React from 'react';

const UrgencyLabel = ({urgency, content}) => {
    const getStyles = (urgency) => {
        switch (urgency) {
            case 'high':
                return 'bg-red-50 text-red-700 ring-red-600/20';
            case 'medium':
                return 'bg-yellow-50 text-yellow-700 ring-yellow-600/20';
            case 'low':
                return 'bg-gray-50 text-gray-700 ring-gray-600/20';
            default:
                return 'bg-gray-50 text-gray-700 ring-gray-600/20';
        }
    };

    const styles = getStyles(urgency);

    return (
        <span
            className={`inline-flex items-center rounded-md px-2 py-1 whitespace-nowrap text-xs font-medium ring-1 ring-inset ${styles}`}
        >
      {content}
    </span>
    );
};

export const TranslateUrgency = (urgency) => {
    const translations = {
        high: 'hoog',
        medium: 'medium',
        low: 'laag',
    };

    return translations[urgency] || urgency;
};

export default UrgencyLabel;
