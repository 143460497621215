import React from "react";
import {ActionLink} from "../layout/LandingLayout";

export default function NotFound() {
    return (
        <>
            <main className="justify-center grid min-h-full place-items-start bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-gray-900">404</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Pagina niet
                        gevonden</h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we kunnen de pagina die je zoekt niet
                        vinden.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <ActionLink to={"/"}>
                            <span className={`text-gray-900 hover:text-gray-500`}>
                                terug naar startpagina <span aria-hidden="true">&rarr;</span>
                            </span>
                        </ActionLink>
                    </div>
                </div>
            </main>
        </>
    )
}