export const convertItems = (items, translateFunction) => {
    if (!items) {
        return [];
    }
    return items.map((item) => {
        return {
            id: item,
            name: translateFunction(item),
        };
    });
};