import {Fragment, useEffect, useRef, useState} from "react";
import {useTokens} from "./TokenContext";
import {auth_api, server_api} from "../axios";
import {Dialog, Transition} from '@headlessui/react';

const CHECK_INTERVAL = 1000 * 5; // Check every 5 seconds
const POPUP_THRESHOLD = 1000 * 60; // 1 minute
const TIME_CONVERSION_FACTOR = 1000000; // Add a descriptive comment for this

export function TimeOutNotifier({children}) {
    const {decodedToken, deleteTokens} = useTokens();
    const [isSessionAboutToExpire, setIsSessionAboutToExpire] = useState(false);
    const [signedOffDueToTimeout, setSignedOffDueToTimeout] = useState(false);
    const [lastCall, setLastCall] = useState(null);

    useEffect(() => {
        if (decodedToken === null || lastCall === null) {
            return; // Exit early if no token is available or no last call is available
        }

        const sessionTTL = decodedToken.custom_claims.session.ttl / TIME_CONVERSION_FACTOR;

        function checkSessionExpiry(sessionTTL) {
            const currentTime = new Date().getTime();
            const timeLeft = lastCall + sessionTTL - currentTime;

            if (timeLeft <= 0) {
                setSignedOffDueToTimeout(true);
                setIsSessionAboutToExpire(false);
                setLastCall(null);
                deleteTokens();
            } else if (timeLeft <= POPUP_THRESHOLD) {
                setIsSessionAboutToExpire(true);
            } else if (isSessionAboutToExpire || signedOffDueToTimeout) {
                setIsSessionAboutToExpire(false);
                setSignedOffDueToTimeout(false);
            }
        }

        checkSessionExpiry(sessionTTL);
        const periodicSessionExpiryCheck = setInterval(() => checkSessionExpiry(sessionTTL), CHECK_INTERVAL);

        return () => {
            clearInterval(periodicSessionExpiryCheck);
        };
    }, [lastCall, decodedToken, deleteTokens]);

    useEffect(() => {
        // Define the interceptor function
        const updateLastCallOnSuccess = (response) => {
            if (response.status >= 200 && response.status < 300) {
                setLastCall(new Date().getTime());
            }
            return response;
        };

        // Set up interceptors for both APIs
        const authInterceptor = auth_api.interceptors.response.use(updateLastCallOnSuccess);
        const serverInterceptor = server_api.interceptors.response.use(updateLastCallOnSuccess);

        // Cleanup: remove the interceptors when the component is unmounted
        return () => {
            auth_api.interceptors.response.eject(authInterceptor);
            server_api.interceptors.response.eject(serverInterceptor);
        };
    }, []);


    return (
        <>
            {children}
            {signedOffDueToTimeout && <SessionExpired/>}
            <SessionAboutToExpire
                isOpen={isSessionAboutToExpire}
                signOut={(event) => {
                    event.preventDefault();
                    setIsSessionAboutToExpire(false)
                    deleteTokens();
                }}
                onExtend={() => {
                    auth_api.get('/authorized-ping');
                    setIsSessionAboutToExpire(false)
                }}
                onClose={() => {
                    if (!signedOffDueToTimeout) auth_api.get('/authorized-ping');
                    setIsSessionAboutToExpire(false)
                }}
            />
        </>
    );
}

function SessionAboutToExpire({isOpen, signOut, onExtend, onClose}) {
    const staySignedIn = useRef(null);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={staySignedIn} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Afmelden?
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Je staat op het punt automatisch afgemeld te worden.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400 sm:col-start-2"
                                        onClick={onExtend}
                                        ref={staySignedIn}
                                    >
                                        Blijf ingelogd
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={signOut}
                                    >
                                        Uitloggen
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function SessionExpired() {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            Je bent automatish afgemeld
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Log opnieuw in om verder te gaan.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Sluiten
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
